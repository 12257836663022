<div class="row mb-3 justify-content-center" [ngClass]="{'background align-items-center': content.img_url && content.content}" *ngFor="let content of content">
  <ng-container *ngIf="content.img_url && content.content">
      <div class="col-lg-6 p-0">
          <img class="img" *ngIf="content.img_layout == 'img-left'" [src]="content.img_url" [alt]="content.img_blue_alt">
          <div [innerHTML]="content.content" *ngIf="content.img_layout == 'img-right'"></div>
      </div>
      <div class="col-lg-6 p-0">
          <img class="img" *ngIf="content.img_layout == 'img-right'" [src]="content.img_url" [alt]="content.img_blue_alt">
          <div [innerHTML]="content.content" *ngIf="content.img_layout == 'img-left'"></div>
      </div>
  </ng-container>
  <ng-container *ngIf="content.content && !content.img_url">
      <div class="col-lg-12">
          <div [innerHTML]="content.content"
          [ngClass]="{
          'text-center-drupal' : content.alignmentSettings.isCenterText,
          'text-justified-drupal' : content.alignmentSettings.isJustifiedText,
          'text-right-drupal' : content.alignmentSettings.isRightText,
          'text-left-drupal' : content.alignmentSettings.isLeftText,
          'text-pink' : content.textColorSettings.isPink,
          'text-blue' : content.textColorSettings.isBlue,
          'text-italic' : content.textColorSettings.isItalic
          }"></div>
      </div>
  </ng-container>
  <ng-container *ngIf="!content.content && content.img_url">
      <img class="img" [src]="content.img_url" [alt]="content.img_alt" style="width:auto;">
  </ng-container>
  <ng-container *ngIf="content.button_content && !content.content && !content.img_url">
      <div class="text-center">
          <button type="button" class="btn btn-primary button-style" [innerHTML]="content.button_content"></button>
      </div>
  </ng-container>
  <ng-container *ngIf="content.text_condensed && !content.youtube_video && !content.img_url && !content.content">
      <div [innerHTML]="content.text_condensed" [ngClass]="{'text-con' : isTextCondensed}"></div>
  </ng-container>
  <ng-container *ngIf="content.text_highlighted_content && !content.text_condensed && !content.youtube_video && !content.img_url && !content.content">
      <div [innerHTML]="content.text_highlighted_content" [ngClass]="{'bgGreen' : isTextBackgroundGreen}"></div>
  </ng-container>
  <ng-container *ngIf="content.video && !content.text_condensed && !content.youtube_video && !content.img_url && !content.content && !content.button_content">
      <app-video-player style="padding-top: 15px;border-radius:20px;"
          [options]="{fluid: true,  aspectRatio: '16:9', autoplay: false, poster: content.video_thumbnail, sources: content.sources}">
      </app-video-player>
  </ng-container>
  <ng-container *ngIf="content.youtube_video && !content.img_url && !content.content">
      <div class="col-lg-12 text-center">
        <iframe class="iframe-size" [src]="content.youtube_video | kurzussafe : 'resourceUrl'" frameborder="0"></iframe>
      </div>
    </ng-container>
</div>
<div class="container justify-content-center" *ngFor="let body of pageBody">
  <div [innerHTML]="body"></div>

</div>
<div style="display:flex; justify-content:center;" class="my-3">
  <button class="btn bcolor" (click)="back()">Vissza</button>
</div>

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowUp, faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons';
import { first, Subject, Subscription, takeUntil } from 'rxjs';
import { FooldalService } from 'src/app/services/fooldal.service';
import { HtmlconvertService } from 'src/app/services/htmlconvert.service';
import { SharedService } from 'src/app/services/shared.service';
import { extractSources } from 'src/app/services/util';
import { VideoStatusService } from 'src/app/services/video-status.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kurzus',
  templateUrl: './kurzus.component.html',
  styleUrls: ['./kurzus.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('collapsed <=> expanded', animate('200ms ease-out')),
    ]),
  ]
})
export class KurzusComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  baseUrl = environment.webBaseUrl;
  block: any[] = [];
  showLessons: boolean = false;
  lessonStates: boolean[] = [];
  choosedCourse: any;
  courseTitle: string = '';
  completed: any[] = [];
  videoIds: any[] = [];
  lessonColor: string = 'darkgray';
  visible: boolean = false;
  downArrow = faArrowDown;
  upArrow = faArrowUp;
  private currentLesson: any[] = [];
  private isFirstFalseAfterTrue: boolean = true;
  public videoEnded?: boolean;
  private obj = {
    tema_title: '',
    tema_description: '',
    lessons: [] as {}[],
    image_url: ''
  };
  firstBlock: boolean = true;
  showModal: boolean = false;
  modalTitle: string = '';
  modalContent: string = '';
  found: boolean = false;
  faRegularHeart = faRegularHeart;
  faSolidHeart = faSolidHeart;
  allLessons: any[] = [];

  constructor(private fooldalService: FooldalService,
    private htmlconvertService: HtmlconvertService,
    private router: ActivatedRoute,
    private routerr: Router,
    private videoStatusService: VideoStatusService,
    private zone: NgZone,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private el: ElementRef

  ) {
    this.sharedService.spinner$.next(true);
    this.videoStatusService.lessonFulfilled$.pipe(takeUntil(this.destroy$)).subscribe((fulfillmentUuid) => {
      const i = this.allLessons.findIndex(lesson => lesson.fulfillment_uuid === fulfillmentUuid);
      if (i !== -1) {
        this.allLessons[i+1].disabled = false;
      }
    });
    if(localStorage.getItem('currentLessonIndex')) {
      const i = Number(localStorage.getItem('currentLessonIndex'));
      localStorage.removeItem('currentLessonIndex');
      this.lessonStates[i] = true;
      this.sharedService.spinner$.pipe(takeUntil(this.destroy$)).subscribe((e) => {
        if(!e) {
          setTimeout(() => {
            const firstElement = this.el.nativeElement.querySelector('.disabled-button');
            console.warn(firstElement);
          if (firstElement) {
            firstElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
          else {

            const openedblock = this.el.nativeElement.querySelector('.expanded');
            console.warn(openedblock);
            if(openedblock) {
              openedblock.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          }
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  toggleTextOverflow(lesson: any) {
    lesson.isTextOverflow = !lesson.isTextOverflow;

  }

  isTextOverflowing(lesson: any) {
    const textContainer = document.createElement('div');
    textContainer.innerHTML = lesson.lessons_desc;
    document.body.appendChild(textContainer);
    const isOverflowing = textContainer.scrollHeight > 145;
    document.body.removeChild(textContainer);
    return isOverflowing;
  }

  getLessonClass(current: boolean): boolean {
    return (current === false && this.isFirstFalseAfterTrue);
  }

  getLessonColor(lesson:any): string {
    let lessonColor: string;
    let current = lesson.completed;
    if (current === true) {
      this.isFirstFalseAfterTrue = true;
      lessonColor = 'done';
      this.visible = true;
    } else if (current === false && this.isFirstFalseAfterTrue) {
      this.isFirstFalseAfterTrue = false;
      lessonColor = 'next';
      this.visible = true;
    } else {
      lessonColor = 'disabled';
      this.visible = false;
      lesson.disabled = true;
    }
    return lessonColor;
  }

  toggleLesson(index: number): void {
    this.lessonStates.forEach((_, i) => {
      if (i !== index) {
        this.lessonStates[i] = false;
      }
    });
    this.lessonStates[index] = !this.lessonStates[index];
  }


  extractVideoId(url: string): string | null {
    const regex = /[?&]v=([^&#]*)/i;
    const match = regex.exec(url);
    return match ? match[1] : null;
  }

  ngOnInit(): void {
    const baseUrl = this.fooldalService.getBaseUrl();
    this.courseTitle = this.router.snapshot.params['title'];
    this.fooldalService.getCourses().subscribe(crouse => {
      for (const [key, value] of Object.entries(crouse)) {
        if (key === 'courses') {
          for (let i in value) {
            if (this.courseTitle === value[i].title[0].value) {
              this.found = true;

              this.fooldalService.enrolledUserOutline(value[i].cid[0].value).subscribe((out) => {
                for (const [key, v] of Object.entries(out)) {
                  if (v.title != undefined && v.title[0].value != this.router.snapshot.params['title']) {
                    continue;
                  }
                  if (key === 'outline') {
                    this.firstBlock = true;
                    for (let i in v) {

                      this.obj = {
                        tema_title: "",
                        tema_description: "",
                        lessons: [] as {}[],
                        image_url: ''
                      };

                      if (v[i].node.hasOwnProperty("title")) {
                        this.obj.tema_title = v[i].node.title[0].value;
                      }
                      if (v[i].node.hasOwnProperty("body") && v[i].node.body.length > 0) {
                        this.obj.tema_description = v[i].node.body[0].value;
                      }
                      if (v[i].topic_image) {

                        this.obj.image_url = baseUrl + '/' + v[i].topic_image;
                      }

                      if (Array.isArray(v[i].lessons)) {
                        this.checkAndSetVideoEnded(v[i]);
                        if (v[i].lessons.length > 0) {
                          this.currentLesson.push(v[i]);

                        }

                      }
                      this.block.push(this.obj);

                    }

                    //
                    this.block.forEach(element => {
                      //
                      for (let i in element.lessons) {

                        this.completed.push(element.lessons[i].completed);
                      }
                    });
                  }

                }
                this.fooldalService.getFavoritesVideos().subscribe((v:any) => {
                  this.allLessons.forEach((lesson, index) => {
                    const found = v.find((video: any) => video.mid == lesson.mid);
                    if (found) {
                      this.allLessons[index].isItFavorite = true;
                    }
                  });
                  this.sharedService.spinner$.next(false);
                });
              });

            }
          }
        }
      }
      if(!this.found) {
        this.fooldalService.getCourseOutlineByPreviewTitle(this.courseTitle).subscribe((out:any) => {

                for (const [key, value] of Object.entries(out)) {
                  const v = value as any;
                  if (v.title != undefined && v.title[0].value != this.router.snapshot.params['title']) {
                    continue;
                  }
                  if (key === 'outline') {
                    this.firstBlock = true;
                    for (let i in v) {

                      this.obj = {
                        tema_title: "",
                        tema_description: "",
                        lessons: [] as {}[],
                        image_url: ''
                      };

                      if (v[i].node.hasOwnProperty("title")) {
                        this.obj.tema_title = v[i].node.title[0].value;
                      }
                      if (v[i].node.hasOwnProperty("body") && v[i].node.body.length > 0) {
                        this.obj.tema_description = v[i].node.body[0].value;
                      }
                      if (v[i].topic_image) {

                        this.obj.image_url = baseUrl + '/' + v[i].topic_image;
                      }

                      if (Array.isArray(v[i].lessons)) {

                        this.checkAndSetVideoEnded(v[i]);
                        if (v[i].lessons.length > 0) {
                          this.currentLesson.push(v[i]);

                        }

                      }
                      this.block.push(this.obj);

                    }

                    //
                    this.block.forEach(element => {
                      //
                      for (let i in element.lessons) {

                        this.completed.push(element.lessons[i].completed);
                      }
                    });
                  }

                }
                this.sharedService.spinner$.next(false);
              });
      }
    })



  }

  onLessonInfoClick(event: Event, lesson: any, fulfillmentuuid: string) {
    if(lesson.disabled) {
      return;
    }
    const clickedElement = event.target as HTMLElement;
    event.preventDefault();
    if (clickedElement.tagName === 'A' && this.currentLesson.length > 0) {

        const watchedBody = {
          "data": {
            "type": "course_object_fulfillment--course_object_fulfillment",
            "id": fulfillmentuuid,
            "attributes": {
              "complete": true
            }
          }
        };
        const currentUrl = window.location.href;
        localStorage.setItem('backUrl', currentUrl);
        const index = this.lessonStates.findIndex(state => state);
        localStorage.setItem('currentLessonIndex', index.toString());
        this.fooldalService.patchVideoWatched(watchedBody, fulfillmentuuid).subscribe(() => window.location.href = clickedElement.getAttribute('href')!);

      }
  }

  toggleLike(videoNid: number) {

    const videoIndex = this.allLessons.findIndex(vid => vid.mid === videoNid);
    if (videoIndex !== -1) {
      const likedData = {
        "entity_type": "media",
        "entity_id": videoNid,
        "flag_id": "favorite_videos"

      }
      this.fooldalService.getFavoritesVideos().subscribe((v:any) => {
        if(v.length < 12 || this.allLessons[videoIndex].isItFavorite) {
          this.fooldalService.likedVideos(likedData).subscribe(liked => {
            this.allLessons[videoIndex].isItFavorite = !this.allLessons[videoIndex].isItFavorite;
          }, error => {
            if (error.status === 422) {
              //this.loginError = error.error.message;
            }
            const modal1 = document.getElementById('exampleModal1');
            if (modal1) {
              modal1.style.display = 'block';
              modal1.classList.add('show');
            }
            this.showModal = true;
            this.renderer.addClass(document.body, 'no-scroll');
            this.modalContent = "Megtelt a kedvencek válogatásod, ha szeretnél újat ide menteni, előbb valamit törölnöd kell! Lehet, hogy bosszantó, vagy nehéz döntés… de sajnos vannak ilyenek! Kérlek döntsd el mi nem kell, hogy helyet csinálhass egy új kedvencnek!";
          });
        } else {
          const modal1 = document.getElementById('exampleModal1');
            if (modal1) {
              modal1.style.display = 'block';
              modal1.classList.add('show');
            }
            this.showModal = true;
            this.renderer.addClass(document.body, 'no-scroll');
            this.modalContent = "Megtelt a kedvencek válogatásod, ha szeretnél újat ide menteni, előbb valamit törölnöd kell! Lehet, hogy bosszantó, vagy nehéz döntés… de sajnos vannak ilyenek! Kérlek döntsd el mi nem kell, hogy helyet csinálhass egy új kedvencnek!";
        }
      });
    }

  }

  private findAncestorByClass(element: HTMLElement, className: string): HTMLElement | null {
    while (element && !element.classList.contains(className)) {
      element = element.parentElement!;
    }
    return element;
  }

  private checkAndSetVideoEnded(vElement: any): void {
    for (let j in vElement.lessons) {

      const lesson_obj = {
        video_id: '',
        lessons_title: '',
        lessons_desc: '' as SafeHtml,
        completed: false,
        y_video_url: '',
        text: '',
        video_url: '',
        video_url_360: '',
        video_url_720: '',
        video_url_1080: '',
        video_url_2K: '',
        video_ended: false,
        thumbnail: '',
        isTextOverflow: true,
        sources: Array(),
        image_url: '',
        image_link: '' as string | null,
        fulfillment_uuid: '',
        mid: '',
        isItFavorite: false,
        lessonId: '',
      };

      lesson_obj.lessons_title = vElement.lessons[j].lesson.field_label[0].value;
      const field_c = this.htmlconvertService.convertToHtml(vElement.lessons[j].lesson.field_content[0].value);
      lesson_obj.lessons_desc = field_c;
      lesson_obj.fulfillment_uuid = vElement.lessons[j].fulfillment.uuid[0].value;
      lesson_obj.lessonId = vElement.lessons[j].lesson.id[0].value;

      const parser = new DOMParser();
      const doc = parser.parseFromString(field_c as string, 'text/html');
      const firstAnchor = doc.querySelector('a');

      if (firstAnchor) {
        const firstHref = firstAnchor.getAttribute('href');
        lesson_obj.image_link = firstHref;
      }
      const baseUrl = this.fooldalService.getBaseUrl();

      if (vElement.lessons[j].lesson.field_video) {
        lesson_obj.video_id = vElement.lessons[j].lesson.id[0].value;
      }

      if (vElement.lessons[j].fulfillment) {
        if (Array.isArray(vElement.lessons[j].fulfillment.complete)) {
          lesson_obj.completed = vElement.lessons[j].fulfillment.complete.length > 0;
        } else {
          lesson_obj.completed = false;
        }

        if (vElement.lessons[j].thumbnail !== null) {
          lesson_obj.thumbnail = baseUrl + vElement.lessons[j].thumbnail;
        }
      }

      if(vElement.lessons[j].lesson_image) {
        lesson_obj.image_url = baseUrl + '/' + vElement.lessons[j].lesson_image;
      }

      if (vElement.lessons[j].video_url && !vElement.lessons[j].video_url?.startsWith('/sites')) {
        const y_video = this.extractVideoId(vElement.lessons[j].video_url);
        lesson_obj.y_video_url = 'https://www.youtube.com/embed/' + y_video;
      }

      lesson_obj.sources = Array();
      if (this.availableVideoUrl(vElement.lessons[j]) && this.availableVideoUrl(vElement.lessons[j]).startsWith('/sites')) {
        lesson_obj.video_url = baseUrl + this.availableVideoUrl(vElement.lessons[j]);
        lesson_obj.sources = extractSources(vElement.lessons[j], baseUrl);
        lesson_obj.mid = vElement.lessons[j].media.mid[0].value;
      }

      this.obj.lessons.push(lesson_obj);
      this.allLessons.push(lesson_obj);
    }
  }

  private availableVideoUrl(lesson:any) {
    return lesson.video_url_360p || lesson.video_url_720p || lesson.video_url_1080p || lesson.video_url_2k || null;
  }


  toggleLessons() {
    this.showLessons = !this.showLessons;
  }

  openModal(platform: string) {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.style.display = 'block';
      modal.classList.add('show');
    }
    this.showModal = true;
    this.renderer.addClass(document.body, 'no-scroll');

    // Az adott platformnak megfelelő tartalom beállítása
    this.modalTitle = '';
    this.modalContent = '';
    switch (platform) {
      case 'lesson-progress':
        this.modalTitle = '';
        this.modalContent = 'Kérlek sorban haladj a leckékkel!';
        break;
    }

    // A popup tartalom és cím beállítása
    const modalTitleElement = document.querySelector('.modal-title');
    const modalBody = document.querySelector('.modal-body');
    if (modalTitleElement && modalBody) {
      modalTitleElement.innerHTML = this.modalTitle;
      modalBody.innerHTML = this.modalContent;
    }
  }

  closeModal() {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
    this.showModal = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  openModalIfNotCompleted(lesson: any) {
    if(lesson.disabled) {
      this.openModal('lesson-progress');
    }
  }

  imgNav(lesson:any) {
    if(lesson.disabled) {
      this.openModalIfNotCompleted(true);
    }
    else if(lesson.image_link) {
      this.routerr.navigate([lesson.image_link])
    }

  }
}

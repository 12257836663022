import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, catchError, switchMap, tap } from 'rxjs';
import { Register } from 'src/app/models/Register';
import { AuthService } from 'src/app/services/auth.service';
import { FooldalService } from 'src/app/services/fooldal.service';
import { passwordMatchValidator } from 'src/app/validators/password-match-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0 })))
    ])
  ]
})
export class SignupComponent implements OnInit {

  registerForm: FormGroup;
  passwordInputStarted: boolean = false;
  loginError?: string;
  showLoginAlert: boolean = false;
  countryCode: string = '';
  countryList: {key: string, value: string}[] = [];
  pilot = environment.pilotMode;

  constructor(private authService: AuthService,private router: Router, private fooldalService: FooldalService) {
    this.registerForm =  new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        display_name: new FormControl('', [Validators.required]),
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        passwordAccept: new FormControl('', [Validators.required]),
        birthdate: new FormControl('', [Validators.required, this.minAge(), this.maxAge()]),
        postcode: new FormControl(''),
        city: new FormControl(''),
        street: new FormControl(''),
        snumber: new FormControl(''),
        country: new FormControl('HU'),
        accept: new FormControl(false, [Validators.requiredTrue]),
        regCode: new FormControl('', [this.exactValueValidator('19750307')])
      },{
        validators: passwordMatchValidator
      }
    )
  }

  private exactValueValidator(allowedValue: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!this.pilot) return null;
      // Check if the control value matches the allowed value
      if (control.value !== allowedValue) {
        return { exactValue: { allowedValue, actualValue: control.value } };
      }
      return null; // Return null if the value matches (i.e., valid)
    };
  }

  @ViewChild('togglePasswordIcon', { read: ElementRef, static:false }) togglePasswordIcon!: ElementRef;
  @ViewChild('togglePasswordConfirmIcon', { read: ElementRef, static:false }) togglePasswordConfirmIcon!: ElementRef;
  @ViewChild('password', { read: ElementRef, static:false }) password!: ElementRef;
  @ViewChild('passwordAccept', { read: ElementRef, static:false }) passwordAccept!: ElementRef;

  public togglePassword() {
    this.togglePasswordIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.togglePasswordIcon.nativeElement.classList.toggle('bi-eye');
    this.password.nativeElement.type = this.password.nativeElement.type === 'password' ? 'text' : 'password';
  }

  public togglePasswordConfirm() {
    this.togglePasswordConfirmIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.togglePasswordConfirmIcon.nativeElement.classList.toggle('bi-eye');
    this.passwordAccept.nativeElement.type = this.passwordAccept.nativeElement.type === 'password' ? 'text' : 'password';
  }

  private minAge(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        const currentYear = new Date().getFullYear();
        // age must be between 18 and 60
        const ageValid = value <= currentYear - 18;

        return !ageValid ? { minAge : true }: null;
    }
}

private maxAge(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      if (!value) {
          return null;
      }

      const currentYear = new Date().getFullYear();
      // age must be between 18 and 60
      const ageValid =  value >= currentYear - 60;

      return !ageValid ? { maxAge : true }: null;
  }
}

  onPasswordInput() {
    this.passwordInputStarted = true;
  }

  ngOnInit(){
    this.fooldalService.getCountryCode().pipe(
      tap(c => {
        let countryArray = [];
        for (const [k, v] of Object.entries(c)) {
          if (k === 'available-countries') {
            for (const [key, value] of Object.entries(v)) {
              countryArray.push({key, value: value as string});
            }
          }
        }
        this.countryList = countryArray;
      })
    ).subscribe();
  }

  onSubmit() {
    this.showLoginAlert = false;
    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid) {
      const year: number = this.registerForm.get('birthdate')?.value;
      const formattedBirthdate =  `${year}-01-01`;
      const userData: Register = {
        mail: { value: this.registerForm.get('email')?.value },
        name: { value: this.registerForm.get('email')?.value },
        display_name: { value: this.registerForm.get('display_name')?.value },
        field_birth_date: { value: formattedBirthdate },
        pass: { value: this.registerForm.get('password')?.value }

      }


      this.authService.register(userData).pipe(
        switchMap((regIn) => {
          for (const [key, value] of Object.entries(regIn)) {
            if (key === 'uuid') {
                  const address: any = {
                    "given_name": this.registerForm.get('first_name')?.value,
                    "family_name": this.registerForm.get('last_name')?.value,
                    "country_code": this.registerForm.get('country')?.value,
                  };
                  if(this.registerForm.get('city')?.value.length > 0) {
                    address['locality'] = this.registerForm.get('city')?.value;
                  }
                  if(this.registerForm.get('postcode')?.value.length > 0) {
                    address['postal_code'] = this.registerForm.get('postcode')?.value;
                  }
                  if(this.registerForm.get('street')?.value.length > 0) {
                    address['address_line1'] = this.registerForm.get('street')?.value;
                  }
                  if(this.registerForm.get('snumber')?.value.length > 0) {
                    address['address_line2'] = this.registerForm.get('snumber')?.value;
                  }
                  const addressBody = {
                    "data": {
                      "type": "profile--customer",
                      "id": value[0].value,
                      "attributes": {
                        "address": address
                      },
                      "relationships": {
                        "uid": {
                          "data": {
                            "type": "user--user",
                            "id": value[0].value
                          }
                        }
                      }
                    }
                  }
                  return this.authService.registerAddress(addressBody);
        }}
        return EMPTY;
      }),
      tap(_ => this.router.navigateByUrl('/success')),
      catchError((error) => {
        this.showLoginAlert = true;
        this.loginError = error.error.message;
        if(error.error.errors.some((item:any) => item.source.pointer === '/data/attributes/address/postal_code')) {
          this.loginError = 'A megadott irányítószám formátuma, vagy a kiválasztott ország nem megfelelő!';
        }
        return EMPTY;
    })).subscribe();
    }
  }

}


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  public spinner$ = new BehaviorSubject<boolean>(false);

  cid: string = '';

  setCID(cid: string) {
    this.cid = cid;
  }

  getCID() {
    return this.cid;
  }
}

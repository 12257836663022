import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FooldalService } from 'src/app/services/fooldal.service';
import { updatePasswordMatchValidator } from 'src/app/validators/password-match-validator';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0 })))
    ])
  ]
})
export class UpdateProfileComponent {
  registerForm: FormGroup;
  passwordChangeForm: FormGroup;
  emailModel: string = '';
  display_nameModel: string = '';
  last_nameModel: string = '';
  first_nameModel: string = '';
  birthdateModel: string = '';
  postcodeModel: string = '';
  cityModel: string = '';
  streetModel: string = '';
  userIdReplace: string = '';
  snumberModel: string = '';
  countryModel: string = '';
  countryCode: string = '';
  showModal: boolean = false;
  modalTitle: string = '';
  modalContent: string = '';
  loginError?: string;
  showLoginAlert: boolean = false;
  countryAvailable: any[] = [];
  found: boolean = false;
  passwordInputStarted: boolean = false;
  platform: string = '';
  modalActionTitle: string = '';
  modalCancelTitle: string = '';

  @ViewChild('password', { read: ElementRef, static:false }) password!: ElementRef;
  @ViewChild('togglePasswordIcon', { read: ElementRef, static:false }) togglePasswordIcon!: ElementRef;
  @ViewChild('toggleNewPasswordIcon', { read: ElementRef, static:false }) toggleNewPasswordIcon!: ElementRef;
  @ViewChild('togglePasswordConfirmIcon', { read: ElementRef, static:false }) togglePasswordConfirmIcon!: ElementRef;
  @ViewChild('newPassword', { read: ElementRef, static:false }) newPassword!: ElementRef;
  @ViewChild('passwordAccept', { read: ElementRef, static:false }) passwordAccept!: ElementRef;

  constructor(private authService: AuthService, private router: Router, private fooldalService: FooldalService,
    private renderer: Renderer2) {
    this.registerForm = new FormGroup(
      {
        email: new FormControl('', [Validators.email, Validators.required]),
        display_name: new FormControl('', [Validators.required]),
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        birthdate: new FormControl('', [Validators.required]),
        postcode: new FormControl(''),
        city: new FormControl(''),
        street: new FormControl(''),
        snumber: new FormControl(''),
        country: new FormControl(''),
      }
    );
    this.passwordChangeForm = new FormGroup(
      {
        newPassword: new FormControl('', [
          Validators.required,
          // minimum 8 characters and at least one special character
          Validators.pattern(/^(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
        ]),
        passwordAccept: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required])
      },{
        validators: updatePasswordMatchValidator
      }
    );
  }

  get passwordSubmitDisabled(): boolean {
    return this.passwordChangeForm.invalid;
  }

  onPasswordInput() {
    this.passwordInputStarted = true;
  }

  public togglePassword() {
    this.togglePasswordIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.togglePasswordIcon.nativeElement.classList.toggle('bi-eye');
    this.password.nativeElement.type = this.password.nativeElement.type === 'password' ? 'text' : 'password';
  }

  public toggleNewPassword() {
    this.toggleNewPasswordIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.toggleNewPasswordIcon.nativeElement.classList.toggle('bi-eye');
    this.newPassword.nativeElement.type = this.newPassword.nativeElement.type === 'password' ? 'text' : 'password';
  }

  public togglePasswordConfirm() {
    this.togglePasswordConfirmIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.togglePasswordConfirmIcon.nativeElement.classList.toggle('bi-eye');
    this.passwordAccept.nativeElement.type = this.passwordAccept.nativeElement.type === 'password' ? 'text' : 'password';
  }

  ngOnInit() {
    const userId =localStorage.getItem('userUuid');
    if (userId) {
      this.userIdReplace = userId?.replace(/"/g, '');
    }
    this.authService.getCurrentUser().subscribe((user) => {
      const value = user.data[0];
      this.emailModel = value.attributes.mail;
      this.display_nameModel = value.attributes.display_name;
      this.birthdateModel = value.attributes.field_birth_date?.substring(0, 4);
    });
      this.fooldalService.getCountryCode().subscribe((countryList: any) => {
        const availableCountries = countryList["available-countries"];
        for (const [code, name] of Object.entries(availableCountries)) {
          // Itt már tudjuk, hogy code egy string, és name is egy string
          this.countryAvailable.push({ code, name });
        }
        this.fooldalService.getCurrentCustomerProfile().subscribe((profile:any) => {

        const value = profile.data;
        this.found = true;
        this.last_nameModel = value.attributes.address.family_name;
        this.first_nameModel = value.attributes.address.given_name;
        this.postcodeModel = value.attributes.address.postal_code;
        this.cityModel = value.attributes.address.locality;
        this.streetModel = value.attributes.address.address_line1;
        this.snumberModel = value.attributes.address.address_line2;
        const countryCode = value.attributes.address.country_code;
        for (let i in this.countryAvailable) {
          if (countryCode === this.countryAvailable[i].code) {
            this.countryModel = this.countryAvailable[i].name;
            this.registerForm.get('country')?.setValue(this.countryAvailable[i].code);
          }
        }
      });
    });
  }

  onSubmitPass() {
    const userData = {
      "data": {
        "type": "user--user",
        "id": this.userIdReplace,
        "attributes": {
          "pass": {
            "value": this.passwordChangeForm.get('newPassword')?.value,
            "existing": this.passwordChangeForm.get('password')?.value
          }
        }
      }
    };
    this.authService.updateUser(this.userIdReplace, userData).subscribe({
      next: (data) => this.openModal('succes-update'),
      error: (error) => this.openModal('wrong-pass')
    });
  }

  onSubmit() {
    this.showLoginAlert = false;
    this.registerForm.markAllAsTouched();
    if(this.registerForm.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const year: number = this.registerForm.get('birthdate')?.value;
    const formattedBirthdate =  `${year}-01-01`;
    const userData = {
      "data": {
        "type": "user--user",
        "id": this.userIdReplace,
        "attributes": {
          "mail": this.registerForm.get('email')?.value,
          "name": this.registerForm.get('email')?.value,
          "field_display_name": this.registerForm.get('display_name')?.value,
          "field_birth_date": formattedBirthdate,
          "pass": {
            "existing": this.registerForm.get('password')?.value
          }
        }
      }
    }


    this.authService.updateUser(this.userIdReplace, userData).subscribe((regIn) => {
      if (regIn) {
        for (const [key, value] of Object.entries(regIn)) {
          if (key === 'data') {
            this.fooldalService.getCountryCode().pipe(
              switchMap(c => {
                for (const [key, value] of Object.entries(c)) {
                  if (key === 'available-countries') {
                    for (const [kk, vv] of Object.entries(value)) {
                      let code = this.registerForm.get('country')?.value
                      if (vv === code) {
                        this.countryCode = kk;
                      }
                    }
                  }
                }
                const addressBody = {
                  "data": {
                    "type": "profile--customer",
                    "id": this.userIdReplace,
                    "attributes": {
                      "address": {
                        "country_code": this.registerForm.get('country')?.value,
                        "locality": this.registerForm.get('city')?.value,
                        "postal_code": this.registerForm.get('postcode')?.value,
                        "address_line1": this.registerForm.get('street')?.value,
                        "address_line2": this.registerForm.get('snumber')?.value,
                        "given_name": this.registerForm.get('first_name')?.value,
                        "family_name": this.registerForm.get('last_name')?.value,
                      }
                    }
                  }
                }
                if(this.found) {
                  return this.authService.updateProfile(this.userIdReplace, addressBody);
                } else {
                  this.found = true;
                  return this.authService.registerAddress(addressBody);
                }
              })
            ).subscribe(s => {
              this.openModal('succes-update');
            }, error => {
              this.showLoginAlert = true;
              this.loginError = error.error.errors[0].detail;
              console.log(error);
              if(error.error.errors.some((item:any) => item.source.pointer === '/data/attributes/address/postal_code')) {
                this.loginError = 'A megadott irányítószám formátuma, vagy a kiválasztott ország nem megfelelő!';
              }
            });
          }
        }
      }
    }, error => {
      this.showLoginAlert = true;
      this.loginError = error.error.errors[0].detail;
      if(error.error.errors.some((item:any) => item.source.pointer === '/data/attributes/address/postal_code')) {
        this.loginError = 'A megadott irányítószám formátuma, vagy a kiválasztott ország nem megfelelő!';
      }
    }
    );
  }

  openModal(platform: string) {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.style.display = 'block';
      modal.classList.add('show');
    }
    this.showModal = true;
    this.platform = platform;
    this.renderer.addClass(document.body, 'no-scroll');

    // Az adott platformnak megfelelő tartalom beállítása
    this.modalTitle = '';
    this.modalContent = '';
    this.modalCancelTitle = 'Bezárás';
    this.modalActionTitle = '';
    switch (platform) {
      case 'succes-update':
        this.modalTitle = 'Sikeres módosítás';
        this.modalContent = 'Az adataid sikeresen módosítva.';
        break;
      case 'wrong-pass':
        this.modalTitle = 'Hibás jelszó!';
        this.modalContent = '';
        break;
      case 'delete-profile':
        this.modalTitle = 'Biztos, hogy törölni szeretnéd a fiókod?';
        this.modalContent = 'Ha igen, minden a fiókhoz tartozó adat és információ törlésre kerül, így nem tudsz többet a kedvezményes hosszabbítási lehetőségekkel élni, nem férsz hozzá a kedvenc videóid válogatáshoz, stb.';
        this.modalActionTitle = 'Igen, törölni szeretném a fiókom';
        this.modalCancelTitle = 'Nem, mégsem törlöm';
        break;
    }

    // A popup tartalom és cím beállítása
    const modalTitleElement = document.querySelector('.modal-title');
    const modalBody = document.querySelector('.modal-body');
    if (modalTitleElement && modalBody) {
      modalTitleElement.innerHTML = this.modalTitle;
      modalBody.innerHTML = this.modalContent;
    }
  }

  closeModal() {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
    this.showModal = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  doModalAction() {
    switch (this.platform) {
      case 'delete-profile':
          this.Logout();
          this.closeModal();
        break;
    }

  }

  onDeleteProfile() {
    this.openModal('delete-profile');
  }



  Logout() {
      this.authService.deleteUser().subscribe(() => {
        localStorage.clear();
        this.router.navigateByUrl("/fooldal");
        window.location.reload();
      });
  }

}

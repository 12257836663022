import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { FooldalService } from 'src/app/services/fooldal.service';
import { HtmlconvertService } from 'src/app/services/htmlconvert.service';
import { extractSources } from 'src/app/services/util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tartalom',
  templateUrl: './tartalom.component.html',
  styleUrls: ['./tartalom.component.css']
})
export class TartalomComponent {
  content: any[] = [];
  baseUrl: string = environment.apiBaseUrl;
  isTextCondensed: boolean = false;
  isTextBackgroundGreen: boolean = false;

  constructor(private fooldalService: FooldalService, private htmlconvertService: HtmlconvertService, private sanitizer: DomSanitizer){}


  extractVideoId(url: string): string | null {
    const regex = /[?&]v=([^&#]*)/i;
    const match = regex.exec(url);
    return match ? match[1] : null;
  }

  ngOnInit(){
    this.fooldalService.getId().subscribe((i) => {
      for(const [key, value] of Object.entries(i)){
        if(Array.isArray(value)){
          for(const [k,v] of Object.entries(value)){
            //
            if(v.title === 'Főoldal'){
              this.fooldalService.getFooldal(v.id).subscribe((page) =>{
                for(const [key, value] of Object.entries(page)){
                  for(var k in value.field_paragraphs){

                    const obj = {content: "" as SafeHtml, img_url: "", img_layout: "",
                    text_condensed: "" as SafeHtml, button_content: "" as SafeHtml,
                    text_highlighted_content: "" as SafeHtml, video: "", video_thumbnail:"",  video_360: "", video_720: "", video_1080: "", video_2K: "",
                    img_alt:"", img_blue_alt: "", youtube_video: "",
                    alignmentSettings: {
                      isCenterText: false,
                      isJustifiedText: false,
                      isRightText: false,
                      isLeftText: false
                    },
                    textColorSettings: {
                      isPink: false,
                      isBlue: false,
                      isItalic: false
                    },
                    sources: Array()
                  };
                    if(value.field_paragraphs[k].type === 'paragraph--image_full'){
                      obj.img_url = this.baseUrl + value.field_paragraphs[k].field_image_full.field_media_image.uri.url;
                      obj.img_alt = value.field_paragraphs[k].field_image_full.field_media_image.meta.alt
                    }else if(value.field_paragraphs[k].type === 'paragraph--image_text_blue'){
                      obj.content = this.htmlconvertService.convertToHtml(value.field_paragraphs[k].field_content.value);
                      if(value.field_paragraphs[k].field_image_inline.field_media_image){
                        obj.img_url = this.baseUrl + value.field_paragraphs[k].field_image_inline.field_media_image.uri.url;
                        obj.img_blue_alt =  value.field_paragraphs[k].field_image_inline.field_media_image.meta.alt;
                      }
                      obj.img_layout = value.field_paragraphs[k].field_layout;
                    }else if(value.field_paragraphs[k].type === 'paragraph--text'){

                      const alignment = value.field_paragraphs[k].field_alignment;

                      obj.alignmentSettings = {
                        isCenterText: alignment === 'align-center',
                        isJustifiedText: alignment === 'align-justified',
                        isRightText: alignment === 'align-right',
                        isLeftText: alignment === 'align-left'
                    };

                      const textColor = value.field_paragraphs[k].field_format;
                      obj.textColorSettings = {
                        isPink: textColor === 'color-pink',
                        isBlue: textColor === 'color-blue',
                        isItalic: textColor === 'style-italic'

                    };

                      if(value.field_paragraphs[k].field_content !== undefined){
                        const paragraph_value = this.htmlconvertService.convertToHtml(value.field_paragraphs[k].field_content.value);
                        obj.content = paragraph_value;
                      }
                    }else if(value.field_paragraphs[k].type === 'paragraph--text_condensed'){
                      const paragraph_condensed = this.htmlconvertService.convertToHtml(value.field_paragraphs[k].field_content.value);
                      obj.text_condensed = paragraph_condensed;
                      if(obj.text_condensed){
                        this.isTextCondensed = true;
                      }else{
                        this.isTextCondensed = false;
                      }
                    }else if(value.field_paragraphs[k].type === 'paragraph--button'){
                      const button_value = value.field_paragraphs[k].field_content.value;
                      const buttonValueAsText: string = this.sanitizer.sanitize(SecurityContext.HTML, button_value) || '';
                      const buttonContentWithoutPTags = buttonValueAsText.replace(/<\/?p[^>]*>/g, '');
                      const buttonContentTrimmed = buttonContentWithoutPTags.trim();
                      obj.button_content = this.sanitizer.bypassSecurityTrustHtml(buttonContentTrimmed);
                    }else if(value.field_paragraphs[k].type === 'paragraph--text_highlighted'){
                      const highlighted_value = this.htmlconvertService.convertToHtml(value.field_paragraphs[k].field_content.value);
                      obj.text_highlighted_content = highlighted_value;
                      if(obj.text_highlighted_content){
                        this.isTextBackgroundGreen = true;
                      }else{
                        this.isTextBackgroundGreen = false;
                      }
                    }else if(value.field_paragraphs[k].type === 'paragraph--video'){

                      obj.video = this.baseUrl + value.field_paragraphs[k].field_video.field_media_video_file.uri.url
                      obj.video_thumbnail = this.baseUrl + value.field_paragraphs[k].field_video.field_thumbnail.field_media_image.uri.url;
                      obj.sources = extractSources(value.field_paragraphs[k].field_video, this.baseUrl);
                    }else if (value.field_paragraphs[k].type === 'paragraph--youtube_video') {
                      const videoId = this.extractVideoId(value.field_paragraphs[k].field_youtube_video.field_media_oembed_video);
                      obj.youtube_video = "https://www.youtube.com/embed/" + videoId
                    }
                    this.content.push(obj);
                  }
                }
              });
            }
          }
        }
      }
    });

  }
}


<div class="container">
    <div class="row">
        <div class="col-lg-12 py-3">
          <form [formGroup]="resetpw" class="col-sm-6" style="margin: 0 auto;">
            <p class="p-pos mt-3">Jelszó módosítás</p>
            <div class="mb-3">
              <label class="form-label">Új jelszó<sup>*</sup></label>
              <div class="d-flex gap-2 align-items-center">
                <input formControlName="newPassword" type="password" #newPassword class="form-control" required>
                  <i class="bi bi-eye-slash" #toggleNewPasswordIcon (click)="toggleNewPassword()"></i>
              </div>
              <div class="info-message">
                  A jelszónak legalább 8 karakter hosszúnak kell lennie, és tartalmaznia kell legalább egy speciális karaktert.
              </div>
              <div *ngIf="resetpw.get('newPassword')?.errors?.['required'] && (resetpw.get('newPassword')?.dirty || resetpw.get('newPassword')?.touched)"
                  class="error-message">
                  Jelszó megadása kötelező!
              </div>
              <div *ngIf="resetpw.get('newPassword')?.errors?.['pattern'] && (resetpw.get('newPassword')?.dirty || resetpw.get('newPassword')?.touched)"
                  class="error-message">
                  A jelszó nem felel meg a követelményeknek!
              </div>
          </div>
          <div class="mb-3">
              <label class="form-label">Új jelszó mégegyszer<sup>*</sup></label>
              <div class="d-flex gap-2 align-items-center">
                <input formControlName="passwordAccept" #passwordAccept type="password" class="form-control">
                  <i class="bi bi-eye-slash" #togglePasswordConfirmIcon (click)="togglePasswordConfirm()"></i>
              </div>

              <div *ngIf="resetpw.get('passwordAccept')?.errors?.['required'] && (resetpw.get('passwordAccept')?.dirty || resetpw.get('passwordAccept')?.touched)"
                  class="error-message">
                  Jelszó megadása mégegyszer kötelező!
              </div>
              <div *ngIf="resetpw.hasError('passwordMismatch')" class="error-message">
                  Jelszavak nem egyeznek meg.
              </div>
            </div>

              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-primary c" (click)="resetPw()" [disabled]="passwordSubmitDisabled">Módosítás</button>
            </div>
          </form>
        </div>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { RolamRoutingModule } from './rolam-routing.module';
import { RolamComponent } from './rolam/rolam.component';



@NgModule({
    declarations: [
        RolamComponent
    ],
    imports: [
        CommonModule,
        RolamRoutingModule,
        SharedModule
    ]
})
export class RolamModule { }

<div class="container">
    <div class="row">
        <div class="col-lg-12 py-3">
            <div class="text-center">
                <p class="p-pos">Kérlek tölts ki minden adatot!</p>
            </div>
            <form [formGroup]="registerForm" class="col-sm-6" style="margin: 0 auto;">
                <div class="mb-3">
                    <label class="form-label">Vezetéknév<sup>*</sup></label>
                    <input formControlName="last_name" type="text" class="form-control">
                    <div *ngIf="registerForm.get('last_name')?.errors?.['required'] && (registerForm.get('last_name')?.dirty || registerForm.get('last_name')?.touched)"
                        class="error-message">
                        Vezetéknév megadása kötelező
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Keresztnév<sup>*</sup></label>
                    <input formControlName="first_name" type="text" class="form-control">
                    <div *ngIf="registerForm.get('first_name')?.errors?.['required'] && (registerForm.get('first_name')?.dirty || registerForm.get('first_name')?.touched)"
                        class="error-message">
                        Keresztnév megadása kötelező
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Hogyan szólítsunk<sup>*</sup></label>
                    <input formControlName="display_name" type="text" class="form-control">
                    <div *ngIf="registerForm.get('display_name')?.errors?.['required'] && (registerForm.get('display_name')?.dirty || registerForm.get('display_name')?.touched)"
                        class="error-message">
                        Megjelenített név megadása kötelező
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Email cím<sup>*</sup></label>
                    <input formControlName="email" type="email" class="form-control">
                    <div *ngIf="registerForm.get('email')?.errors?.['required'] && (registerForm.get('email')?.dirty || registerForm.get('email')?.touched)"
                        class="error-message">
                        Email cím megadása kötelező
                    </div>
                    <div *ngIf="registerForm.get('email')?.errors?.['email'] && (registerForm.get('email')?.dirty || registerForm.get('email')?.touched)"
                        class="error-message">
                        Adj meg érvényes email címet
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Jelszó<sup>*</sup></label>
                    <div class="d-flex gap-2 align-items-center">
                      <input formControlName="password" type="password" #password class="form-control" required
                        (input)="onPasswordInput()">
                        <i class="bi bi-eye-slash" #togglePasswordIcon (click)="togglePassword()"></i>
                    </div>

                    <div *ngIf="registerForm.get('password')?.errors?.['required'] && (registerForm.get('password')?.dirty || registerForm.get('password')?.touched)"
                        class="error-message">
                        Jelszó megadása kötelező
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Jelszó megadása még egyszer<sup>*</sup></label>
                    <div class="d-flex gap-2 align-items-center">
                      <input formControlName="passwordAccept" #passwordAccept type="password" class="form-control">
                        <i class="bi bi-eye-slash" #togglePasswordConfirmIcon (click)="togglePasswordConfirm()"></i>
                    </div>

                    <div *ngIf="registerForm.get('passwordAccept')?.errors?.['required'] && (registerForm.get('passwordAccept')?.dirty || registerForm.get('passwordAccept')?.touched)"
                        class="error-message">
                        Jelszó megadása mégegyszer kötelező
                    </div>
                    <div *ngIf="registerForm.hasError('passwordMismatch')" class="error-message">
                        Jelszavak nem egyeznek meg.
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Születési év<sup>*</sup></label>
                    <input formControlName="birthdate" type="number" class="form-control">
                    <div *ngIf="registerForm.get('birthdate')?.errors?.['required'] && (registerForm.get('birthdate')?.dirty || registerForm.get('birthdate')?.touched)"
                        class="error-message">
                        Születési dátum megadása kötelező
                    </div>
                    <div *ngIf="registerForm.get('birthdate')?.value.toString().length == 4 && registerForm.get('birthdate')?.errors?.['minAge'] && (registerForm.get('birthdate')?.dirty || registerForm.get('birthdate')?.touched)"
                        class="error-message">
                        Ha még nem vagy nagykorú, nem támogatom a várandósságod. Megértésed kérem, így nem tudsz regisztrálni!
                    </div>
                    <div *ngIf="registerForm.get('birthdate')?.value.toString().length == 4 && registerForm.get('birthdate')?.errors?.['maxAge'] && (registerForm.get('birthdate')?.dirty || registerForm.get('birthdate')?.touched)"
                        class="error-message">
                        Nagyon megtisztel, hogy az én nőijóga óráimat szeretnéd, de a Te korodban inkább senior jógát javaslok. Megértésed kérem, így nem tudsz regisztrálni!
                    </div>
                </div>
                <div class="text-center">
                    <p class="p-pos">Cím (a számlázáshoz kell!)</p>
                </div>
                <div class="mb-3">
                  <label class="form-label">Ország</label>
                  <select formControlName="country" class="form-select form-control">
                    <option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</option>
                  </select>
                  <div *ngIf="registerForm.get('country')?.errors?.['required'] && (registerForm.get('country')?.dirty || registerForm.get('country')?.touched)"
                      class="error-message">
                      Ország megadása kötelező
                  </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Irányítószám</label>
                    <input formControlName="postcode" class="form-control">
                    <div *ngIf="registerForm.get('postcode')?.errors?.['required'] && (registerForm.get('postcode')?.dirty || registerForm.get('postcode')?.touched)"
                        class="error-message">
                        Irányítószám megadása kötelező
                    </div>
                    <div *ngIf="registerForm.get('postcode')?.errors?.['pattern'] && (registerForm.get('postcode')?.dirty || registerForm.get('postcode')?.touched)"
                        class="error-message">
                        Adj meg érvényes irányitószámot (pl: 1111)!
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Város</label>
                    <input formControlName="city" type="text" class="form-control">
                    <div *ngIf="registerForm.get('city')?.errors?.['required'] && (registerForm.get('city')?.dirty || registerForm.get('city')?.touched)"
                        class="error-message">
                        Város megadása kötelező
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Utca, tér, egyéb közterület </label>
                    <input formControlName="street" type="text" class="form-control">
                    <div *ngIf="registerForm.get('street')?.errors?.['required'] && (registerForm.get('street')?.dirty || registerForm.get('street')?.touched)"
                        class="error-message">
                        Utca megadása kötelező
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Házszám</label>
                    <input formControlName="snumber" type="text" class="form-control">
                    <div *ngIf="registerForm.get('snumber')?.errors?.['required'] && (registerForm.get('snumber')?.dirty || registerForm.get('snumber')?.touched)"
                        class="error-message">
                        Ház szám megadása kötelező
                    </div>
                </div>

                <div class="mb-3">
                  <div class="form-check">
                  <input formControlName="accept" type="checkbox" class="form-check-input" id="aszfCheck" style="border: 1px solid black;" >
                  <label class="form-check-label" for="aszfCheck">
                    Az <a routerLink="/aszf" target="_blank">ÁSZF-et</a> és az <a routerLink="/gdpr" target="_blank">Adatkezelési tájékoztatót</a> elfogadom<sup>*</sup>
                  </label>
                </div>

                  <div *ngIf="registerForm.get('accept')?.errors?.['required'] && (registerForm.get('accept')?.dirty || registerForm.get('accept')?.touched)"
                      class="error-message">
                      Az ÁSZF és Adatkezelési szabályzat elfogadása kötelező!
                  </div>
              </div>
                <div class="mb-3" *ngIf="loginError && showLoginAlert" [@fadeInOut]>
                    <p class="alert alert-danger">{{loginError}}</p>
                </div>

                <div class="mb-3" *ngIf="pilot">
                  <label class="form-label">Regisztrációs kód</label>
                  <input formControlName="regCode" type="text" class="form-control">
              </div>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary c" (click)="onSubmit()">Regisztráció</button>
                </div>
            </form>
        </div>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { TutorialRoutingModule } from './tutorial-routing.module';
import { TutorialComponent } from './tutorial.component';



@NgModule({
    declarations: [
        TutorialComponent
    ],
    imports: [
        CommonModule,
        TutorialRoutingModule,
        SharedModule
    ]
})
export class TutorialModule { }

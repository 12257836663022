<div class="container">
  <div class="row">
    <div class="col-lg-12 py-3">
      <form [formGroup]="loginForm" class="col-sm-7" style="margin: 0 auto;" (keydown.enter)="onSubmit()">
        <div class="mb-3">
          <label class="form-label">E-mail cím</label>
          <input formControlName="username" type="text" class="form-control">
          <div *ngIf="loginForm.get('username')?.errors?.['required'] && (loginForm.get('username')?.dirty || loginForm.get('username')?.touched)"
               class="error-message">
            E-mail cím megadása kötelező.
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Jelszó</label>
          <div class="d-flex gap-2 align-items-center">
            <input formControlName="password" type="password" class="form-control" #password />
            <i class="bi bi-eye-slash" #togglePasswordIcon (click)="togglePassword()"></i>
          </div>
          <div *ngIf="loginForm.get('password')?.errors?.['required'] && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)"
               class="error-message">
            Jelszó megadása kötelező.
          </div>
        </div>

        <div class="mb-3" style="display: flex; justify-content: space-between;">
          <label routerLink="/lost-password">Elfelejtette a jelszavát</label>
          <label *ngIf="!pilot" class="label-pink" routerLink="/signup">Regisztráció</label>
        </div>
        <div class="mb-3" *ngIf="loginError && showLoginAlert" [@fadeInOut]>
          <p class="alert alert-danger">{{loginError}}</p>
        </div>
        <button type="button" class="btn btn-primary c" (click)="onSubmit()">Bejelentkezés</button>
        <div class="my-3" *ngIf="pilot">
          A tesztüzemmód idejére levetettem a regisztrációs linket, nem tudsz magad regisztrálni.<br/>
          Ha érdeklődni szeretnél, be tudsz-e még a tesztelők közé kerülni, erre a címre írj nekem, a tárgyban jelölve, hogy a tesztelés érdekel: <table><tr><td>erika</td><td>@</td><td>babafeszkelo.hu</td></tr></table>
        </div>
      </form>




    </div>
  </div>
</div>

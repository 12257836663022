<div class="wrapper mx-auto">
    <div class="arrow-steps clearfix">
        <div class="step"> <span>Adatok</span> </div>
        <div class="step"> <span>Összegzés</span> </div>
        <div class="step current"><span> Véglegesítés</span> </div>
    </div>
</div>
<div class="text-center message m-5 p-3 mx-auto rounded">
    Sikeres megrendelés. Számládat hamarosan e-mailen küldjük. Az oldal hamarosan átirányít az előfizetéseidhez.
</div>
<div class="text-center m-5" *ngFor="let product of toPayProduct">
    <p class="cart-content">Megrendelt kurzus</p>
    <span class="course-name">{{product.title}}</span>
</div>

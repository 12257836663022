<footer class="footer footerstyle" style="margin-top:auto;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm">
        <h3>Kapcsolat </h3>
        <div>
          Balogh Erika e.v.
        </div>
        <div>
          nyilvántartási szám: 54059207
        </div>
        <div>
          adószám: 55388087-1-41
        </div>
        <div>
          <table class="mail-table">
            <tr>
              <td>erika</td>
              <td>@</td>
              <td>babafeszkelo.hu</td>
            </tr>
          </table>
          <table class="mail-table secret">
            <tr>
              <td>spam</td>
              <td>@</td>
              <td>babafeszkelo.hu</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-sm text-end">
        <div>
          <a class="footera afooter" style="margin-right: 10px;" (click)="openModal('fb')"><fa-icon
              [icon]="faFB"></fa-icon></a>
          <a class="footera afooter" style="margin-right: 10px;" (click)="openModal('tiktok')"><fa-icon
              [icon]="fatikTok"></fa-icon></a>
          <a class="footera afooter" (click)="openModal('insta')"><fa-icon [icon]="faInsta"></fa-icon></a>
        </div>
        <div><a class="footera afooter" routerLink="aszf" target="_blank">ÁSZF</a></div>
        <div><a class="footera afooter" routerLink="gdpr" target="_blank">Adatkezelés</a></div>
        <div><a class="footera afooter" routerLink="GYIK" target="_blank">GYIK</a></div>
        <!--<div><span>powered by: <a class="footera" href="https://datastep.solutions/" target="_blank">Datastep Hungary Kft.</a></span>
        </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="d-flex align-items-center justify-content-center mt-2">
          <fa-icon style="margin-right: 5px;" [icon]="faCopy"></fa-icon>
          <p class="ml-2 mb-0">Minden jog fentartva</p>
        </div>
        <p>Tudomásom és beleegyezésem nélkül tilos az oldal szöveges, képi és videó tartalmainak kimásolása,
          terjesztése. Sok munkám van benne, kérlek tartsd tiszteletben a szerzői és előadói jogokat!</p>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showModal"></div>
  <div class="modal fade modalc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    id="exampleModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            </button>
          </div>
          <div class="modal-body">
            <div>{{ modalContent }}</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"
              (click)="closeModal()">Bezárás</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</footer>

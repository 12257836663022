<div class="row mb-3" [ngClass]="{'background align-items-center': content.img_url && content.content}"
  *ngFor="let content of content">
  <ng-container *ngIf="content.img_url && content.content">
    <div class="col-lg-6 p-0">
      <img class="img" *ngIf="content.img_layout == 'img-left'" [src]="content.img_url" [alt]="content.img_blue_alt">
      <div [innerHTML]="content.content" *ngIf="content.img_layout == 'img-right'"></div>
    </div>
    <div class="col-lg-6 p-0">
      <img class="img" *ngIf="content.img_layout == 'img-right'" [src]="content.img_url" [alt]="content.img_blue_alt">
      <div [innerHTML]="content.content" *ngIf="content.img_layout == 'img-left'"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="content.content && !content.img_url">
    <div class="col-lg-12">
      <div [innerHTML]="content.content" [ngClass]="{
        'text-center-drupal' : content.alignmentSettings.isCenterText,
        'text-justified-drupal' : content.alignmentSettings.isJustifiedText,
        'text-right-drupal' : content.alignmentSettings.isRightText,
        'text-left-drupal' : content.alignmentSettings.isLeftText,
        'text-pink' : content.textColorSettings.isPink,
        'text-blue' : content.textColorSettings.isBlue,
        'text-italic' : content.textColorSettings.isItalic
        }"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!content.content && content.img_url">
    <img class="img" [src]="content.img_url" [alt]="content.img_alt">
  </ng-container>
  <ng-container *ngIf="content.button_content && !content.content && !content.img_url">
    <div class="text-center">
      <button type="button" class="btn btn-primary button-style" [innerHTML]="content.button_content"></button>
    </div>
  </ng-container>
  <ng-container *ngIf="content.text_condensed && !content.youtube_video && !content.img_url && !content.content">
    <div [innerHTML]="content.text_condensed" [ngClass]="{'text-con' : isTextCondensed}"></div>
  </ng-container>
  <ng-container
    *ngIf="content.text_highlighted_content && !content.text_condensed && !content.youtube_video && !content.img_url && !content.content">
    <div [innerHTML]="content.text_highlighted_content" [ngClass]="{'bgGreen' : isTextBackgroundGreen}"></div>
  </ng-container>
</div>
<ng-container>
  <div class="container mt-2">
    <div class="row">
      <div class="col-md-6 offset-md-3 text-center">
        <p class="hall-title">Aktuális foglalkozások</p>
      </div>
    </div>
    <ng-container  *ngFor="let hall of hallProducts; let i = index" >
    <div class="row mb-3">
      <div class="col-6">
        <div class="row">
          <p class="pink-title">{{hall.title}}</p>
        </div>

        <div class="row">
          <div class="col-12">
            <p><span class="details">Dátum:</span> {{hall.date}}</p>
            <p><span class="details">Helyszín:</span> {{hall.place}}</p>
          </div>

          <p><span class="details">Ár:</span> {{hall.price}}</p>

        </div>
      </div>

      <div class="col-6">
        <div class="text-end margin-button">
          <button *ngIf="hall.is_booked && !this.isLoggedIn" class="btn btn-primary button-style custom"
            (click)="openBookModal(hall.product_id, hall.convertedDate)">Előjegyzés</button>
          <button *ngIf="hall.is_booked && this.isLoggedIn" class="btn btn-primary button-style custom"
            (click)="openBookModalInLogged(hall.product_id, hall.convertedDate)">Előjegyzés</button>
          <button *ngIf="hall.is_can_pay" class="btn btn-primary button-style custom"
            (click)="addCart(hall.var_type, hall.var_id)">Jelentkezem</button>
        </div>
      </div>

    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="row" [innerHTML]="hall.summary"></div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="row" [innerHTML]="hall.desc"></div>
      </div>
    </div>
    <div class="line"></div>
    </ng-container>

  </div>
</ng-container>
<!-- Modal 1 - Csak szöveges tartalom -->
<div class="overlay" *ngIf="showModal1"></div>
<div class="modal fade modalc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  id="exampleModal1" [class.show]="showModal1" *ngFor="let hall of hallProducts;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle1 }}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal(1)"></button>
        </div>
        <div class="modal-body">
          <p>Köszönöm érdeklődésed a {{hall.title}} foglalkozás kapcsán! Emailben értesítelek, amint a tényleges jelentkezés megnyitásra kerül.<br>Era</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal(1)">Bezárás</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal 2 - Input mező és gomb -->
<div class="overlay" *ngIf="showModal2"></div>
<div class="modal fade modalc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  id="exampleModal2" [class.show]="showModal2">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Előjegyzés</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal(2)"></button>
        </div>
        <div class="modal-body text-center">
          <form [formGroup]="bookForm">
            <div class="mt-3">
              <p>Email cím:</p>
              <input formControlName="email" type="text">
            </div>
          </form>
          <div class="mt-3 text-end">
            <button type="button" class="btn btn-primary button-style" (click)="sendBookForm()">Küldés</button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal(2)">Bezárás</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row mb-3" [ngClass]="{'background align-items-center': content.img_url && content.content}"
  *ngFor="let content of content">
  <ng-container
    *ngIf="content.video && !content.text_condensed && !content.youtube_video && !content.img_url && !content.content && !content.button_content">
    <app-video-player style="padding-top: 15px;"
      [options]="{fluid: true,  aspectRatio: '16:9', autoplay: false, poster: content.video_thumbnail, sources: content.sources}">
    </app-video-player>
  </ng-container>
  <ng-container *ngIf="content.youtube_video && !content.img_url && !content.content">
    <div class="col-lg-12 text-center">
      <iframe class="iframe-size" [src]="content.youtube_video | fbpsafe : 'resourceUrl'" frameborder="0"></iframe>
    </div>
  </ng-container>
</div>

<div class="container">
  <div class="row">
    <div class="text-center">
      <p class="p-pos">GYIK</p>
    </div>
    <div *ngFor="let category of faq; let i = index" class="mb-3 course">
      <div class="d-flex justify-content-between align-items-center" (click)="toggleFaq(i)" style="cursor:pointer; padding: 0px 20px;">
        <span class="course-title" style="font-size:115%">{{ category.titleCat }}</span>
        <button type="button" class="btn btn-primary button-style d-flex align-items-center justify-content-center gap-2" style="width:auto;padding:6px 12px;"><fa-icon [icon]="category.open ? upArrow : downArrow" class="down-arrow"></fa-icon></button>
      </div>
      <div *ngIf="category.open">
        <div *ngFor="let question of category.questions" [@fadeInOut]  style="margin-left:25px;margin-right:25px;margin-bottom:5px;">
          <div (click)="question.isOpen = !question.isOpen" style="cursor:pointer;color: #0A606F;font-weight:bold;font-size:110%; display:flex; align-items:center; gap: 5px;margin-top:5px;">
            <span>
              {{ question.question }}
            </span>
            <span style="color: #C804AC;">
              <fa-icon [icon]="question.isOpen ? upArrow : downArrow" class="down-arrow"></fa-icon>
            </span>
          </div>
          <div *ngIf="question.isOpen" [innerHTML]="question.answer" [@fadeInOut]>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { updatePasswordMatchValidator } from 'src/app/validators/password-match-validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {

  resetpw: FormGroup;
  user: string | null = null;
  token: string | null = null;

  @ViewChild('toggleNewPasswordIcon', { read: ElementRef, static:false }) toggleNewPasswordIcon!: ElementRef;
  @ViewChild('togglePasswordConfirmIcon', { read: ElementRef, static:false }) togglePasswordConfirmIcon!: ElementRef;
  @ViewChild('newPassword', { read: ElementRef, static:false }) newPassword!: ElementRef;
  @ViewChild('passwordAccept', { read: ElementRef, static:false }) passwordAccept!: ElementRef;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
    this.resetpw = new FormGroup(
      {
        newPassword: new FormControl('', [
          Validators.required,
          // minimum 8 characters and at least one special character
          Validators.pattern(/^(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
        ]),
        passwordAccept: new FormControl('', [Validators.required]),
      },{
        validators: updatePasswordMatchValidator
      });
  }

  ngOnInit(){
    this.route.queryParams.subscribe(params => {
      this.user = params['user'];
      this.token = params['token'];
    });
  }

  public toggleNewPassword() {
    this.toggleNewPasswordIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.toggleNewPasswordIcon.nativeElement.classList.toggle('bi-eye');
    this.newPassword.nativeElement.type = this.newPassword.nativeElement.type === 'password' ? 'text' : 'password';
  }

  public togglePasswordConfirm() {
    this.togglePasswordConfirmIcon.nativeElement.classList.toggle('bi-eye-slash');
    this.togglePasswordConfirmIcon.nativeElement.classList.toggle('bi-eye');
    this.passwordAccept.nativeElement.type = this.passwordAccept.nativeElement.type === 'password' ? 'text' : 'password';
  }

  get passwordSubmitDisabled(): boolean {
    return this.resetpw.invalid;
  }

  resetPw() {
    if (this.resetpw.valid) {
      const resetPw = {
        "name" : this.user,
        "temp_pass" : this.token,
        "new_pass" : this.resetpw.get('newPassword')?.value
      }
      this.authService.resetPassword(resetPw).subscribe( pw => {
        this.router.navigate(['/signin']);
      });
    }
  }



}

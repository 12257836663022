<div class="container d-flex justify-content-center align-items-center divh">
    <div class="row text-center">
        <div class="col-12  mb-4">
            <a routerLink="/fooldal">
                <img src="assets/menulogo.png" alt="Logó" class="img-fluid pic">
            </a>
        </div>
        <div class="col-12">
            <p class="display-6 fontc">Sajnos ez az oldal nem létezik. Kattints a logóra, hogy visszakerülj az oldalra.</p>
        </div>
    </div>
</div>

export function extractSources(video: any, baseUrl: string): any[] {
  const sources: any[] = [];
  var url360p: string = video.video_url_360p ? video.video_url_360p : video.field_converted_360p;
  var url720p: string = video.video_url_720p ? video.video_url_720p : video.field_converted_720p;
  var url1080p: string = video.video_url_1080p ? video.video_url_1080p : video.field_converted_1080p;
  var url2K: string = video.video_url_2k ? video.video_url_2k : video.field_converted_2k;
  if(!url2K) {
    url2K = video.video_url_2K ? video.video_url_2K : video.field_converted_2K;
  }
  if(typeof url2K === "object") {
    url360p = video.field_converted_360p?.uri?.url;
    url720p = video.field_converted_720p?.uri?.url;
    url1080p = video.field_converted_1080p?.uri?.url;
    url2K = video.field_converted_2k ? video.field_video?.field_converted_2k.uri?.url : video.field_video?.field_converted_2K?.uri?.url;
  }
  if(video.field_video) {
     url360p = video.field_video?.field_converted_360p?.uri?.url;
     url720p = video.field_video?.field_converted_720p?.uri?.url;
     url1080p = video.field_video?.field_converted_1080p?.uri?.url;
     url2K = video.field_video?.field_converted_2k ? video.field_video?.field_converted_2k.uri?.url : video.field_video?.field_converted_2K?.uri?.url;
  }
  if(url2K) {
    sources.push(getSource(baseUrl, url2K, '2K'));
  }
  if(url1080p) {
    sources.push(getSource(baseUrl, url1080p, '1080p'));
  }
  if(url720p) {
    sources.push(getSource(baseUrl, url720p, '720p'));
  }
  if(url360p) {
    sources.push(getSource(baseUrl, url360p, '360p'));
  }
  return sources;
}

function getType(url: string): string {
  const extension = getPartAfterLastDot(url);
  let type = 'application/dash+xml';
  switch(extension) {
    case 'mp4':
      type = 'video/mp4';
      break;
    case 'm3u8':
      type = 'application/x-mpegURL';
      break;
  }
  return type;
}

function getPartAfterLastDot(input: string): string {
  const lastDotIndex = input.lastIndexOf('.');

  // If there is no dot, return the original string
  if (lastDotIndex === -1) {
    return input;
  }

  // Return the substring after the last dot
  return input.substring(lastDotIndex + 1);
}

function getSource(baseUrl: string, url: string, label: string): any {
  return {
    src: baseUrl + url,
    type: getType(url),
    label: label
  }
}

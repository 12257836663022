import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { VideoJsPlayer } from 'video.js';

@Injectable({
  providedIn: 'root'
})
export class VideoStatusService {
  private lessonFulfilled: Subject<string> = new Subject();
  public lessonFulfilled$ = this.lessonFulfilled.asObservable();
  private players = new Map<string, VideoJsPlayer>();

  markLessonFulfilled(fulfillmentUuid: string) {
    this.lessonFulfilled.next(fulfillmentUuid);
  }

   addPlayer(player: VideoJsPlayer, lessonId: string) {
      this.players.set(lessonId, player);
   }

   pauseAllOtherPlayers(lessonId: string) {
      this.players.forEach((player, key) => {
        if (key !== lessonId) {
          player.pause();
        }
      });
   }


}

<div class="container">
  <div id="tutorial" *ngIf="!loggedUser">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-3">
                  <div class="row no-gutters">
                        <div class="card-div mx-auto">
                          <button class="btn green-button w-100" [routerLink]="'/tutorial-video/'">Segítség az oldal használatához (TUTORIAL)</button>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <div class="text-center">
        <p class="p-pos">Előfizetés </p>
    </div>
    <div id="kurzusaim" *ngIf="loggedUser && active">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3">
                      <div class="row no-gutters">
                            <div class="card-div mx-auto">
                                <p class="card-title p-2">Aktív előfizetéseim</p>

                                <div class="card-body" *ngFor="let courseexpires of courseDetailsExpires">
                                  <div class="course-title" style="position:relative;">
                                    <span class="card-text-name">{{ courseexpires.title }}</span>
                                    <ng-container *ngIf="!courseexpires.videostore">
                                      <button class="btn bcolor"
                                            [routerLink]="'/kurzusok/' + courseexpires.link">Foglalkozások megnyitása</button>
                                    </ng-container>
                                    <ng-container *ngIf="courseexpires.videostore">
                                      <div style="display:flex; flex-direction:column; gap: 5px">
                                        <button class="btn bcolor"
                                            [routerLink]="'/user/videotar_elofizetett'">Videótár megnyitása</button>
                                            <button *ngIf="courseexpires.cancellable" class="btn bcolor"
                                            (click)="openModal('cancel-recurring', courseexpires.licenseUuid)">Előfizetés lemondása</button>
                                      </div>

                                    </ng-container>
                                  </div>
                                    <p class="card-text pt-1" *ngIf="courseexpires.cancellable">Megújul: {{courseexpires.end_date}}</p>
                                    <p class="card-text pt-1" *ngIf="!courseexpires.cancellable && !courseexpires.licenseUuid">Hozzáférés vége: {{courseexpires.end_date}}</p>
                                    <p class="card-text pt-1" *ngIf="!courseexpires.cancellable && courseexpires.licenseUuid">Az előfizetést lemondtad, a hozzáférés vége: {{courseexpires.end_date}}</p>
                                    <div class="description" *ngIf="courseexpires.desc">
                                      <p class="card-text" [innerHTML]="courseexpires.desc" [class.truncated-text]="courseexpires.isTextOverflow"></p>
                                      <p class="read-more-button text-right" *ngIf="isTextOverflowing(courseexpires)" (click)="toggleTextOverflow(courseexpires)">{{ courseexpires.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="kurzusaim-inactive" *ngIf="loggedUser && !active">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="row no-gutters">
                            <div class="card-div mx-auto">
                                <p class="card-title p-2">Aktív előfizetéseim</p>
                                <div class="card-body">
                                    <p class="card-text-name">Jelenleg nincs előfizetésed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="erdekel" *ngIf="loggedUser && !this.hasProm">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="row no-gutters">
                            <div class="card-div mx-auto">
                                <p class="card-title p-2">Ez is érdekelhet</p>
                                <div class="card-body"
                                *ngFor="let courseNonExpires of productsVidForLicens">
                                <div class="course-title">
                                  <span class="card-text-name">{{ courseNonExpires.title }}</span>
                                  <button class="btn bcolor"
                                  (click)="addCart(courseNonExpires.type, courseNonExpires.type_id)">Előfizetek</button>
                                </div>
                                <p class="card-text">Ár: {{ courseNonExpires.price }}</p>
                                <p class="card-text">Előfizetés hossza: {{courseNonExpires.month}}</p>
                                <div class="description" *ngIf="courseNonExpires.desc">
                                  <p class="card-text" [innerHTML]="courseNonExpires.desc" [class.truncated-text]="courseNonExpires.isTextOverflow"></p>
                                  <p class="read-more-button text-right" *ngIf="isTextOverflowing(courseNonExpires)" (click)="toggleTextOverflow(courseNonExpires)">{{ courseNonExpires.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                </div>
                                <div class="line"></div>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--videótéka termékek-->

        <div class="container">
            <div class="row" *ngIf="productsForLicens.length > 0">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="row no-gutters">
                            <div class="card-div mx-auto">
                              <div *ngFor="let courseNonExpires of productsForLicens">
                                <ng-container *ngIf="!isCourseActive(courseNonExpires.sku)">
                                    <div class="card-body">
                                      <div class="course-title">
                                        <span class="card-text-name">{{ courseNonExpires.title }}</span>
                                        <button class="btn bcolor"
                                        (click)="addCart(courseNonExpires.type, courseNonExpires.type_id)">Előfizetek</button>
                                      </div>

                                        <p class="card-text">Ár:
                                          {{ courseNonExpires.price }}
                                        </p>
                                        <p class="card-text">Előfizetés hossza: {{courseNonExpires.month}}
                                        </p>
                                        <div class="description" *ngIf="courseNonExpires.desc">
                                          <p class="card-text" [innerHTML]="courseNonExpires.desc" [class.truncated-text]="courseNonExpires.isTextOverflow"></p>
                                          <p class="read-more-button text-right" *ngIf="isTextOverflowing(courseNonExpires)" (click)="toggleTextOverflow(courseNonExpires)">{{ courseNonExpires.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                        </div>
                                        <div class="line"></div>
                                    </div>
                                </ng-container>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="erdekel" *ngIf="loggedUser && this.hasProm">
        <div class="container">
            <div class="row" *ngIf="courseNonEnrollmentsDetailsExpiresProm.length > 0">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="row no-gutters">
                            <div class="card-div mx-auto">
                                <p class="card-title p-2">Ez is érdekelhet</p>
                                <div *ngFor="let courseNonExpires of courseNonEnrollmentsDetailsExpiresProm">
                                    <ng-container *ngIf="!isCourseActive(courseNonExpires.sku)">
                                        <div class="card-body">
                                          <div class="course-title">
                                            <span class="card-text-name">{{ courseNonExpires.title }}</span>
                                            <button class="btn bcolor"
                                            (click)="addCart(courseNonExpires.type, courseNonExpires.type_id)">Előfizetek</button>
                                          </div>
                                            <p *ngIf="courseNonExpires.price" class="card-text">Teljes ár: {{
                                                courseNonExpires.list_price }}</p>
                                            <p class="card-text">Kedvezményes ár: {{ courseNonExpires.price }}</p>
                                            <p class="card-text">Előfizetés hossza: {{courseNonExpires.month}}</p>
                                            <div class="description" *ngIf="courseNonExpires.desc">
                                              <p class="card-text" [innerHTML]="courseNonExpires.desc" [class.truncated-text]="courseNonExpires.isTextOverflow"></p>
                                              <p class="read-more-button text-right" *ngIf="isTextOverflowing(courseNonExpires)" (click)="toggleTextOverflow(courseNonExpires)">{{ courseNonExpires.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                            </div>
                                            <div class="line"></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--videótéka termékek-->

        <div class="container">
            <div class="row" *ngIf="vidCourseNonEnrollmentsDetailsExpiresProm.length > 0">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="row no-gutters">
                            <div class="card-div mx-auto">
                                <div class="card-body"
                                    *ngFor="let courseNonExpires of vidCourseNonEnrollmentsDetailsExpiresProm">
                                    <div class="course-title">
                                      <span class="card-text-name">{{ courseNonExpires.title }}</span>
                                      <button class="btn bcolor"
                                      (click)="addCart(courseNonExpires.type, courseNonExpires.type_id)">Előfizetek</button>
                                    </div>
                                    <p class="card-text" [innerHTML]="courseNonExpires.desc"></p>
                                    <p *ngIf="courseNonExpires.price" class="card-text">Teljes ár: {{
                                        courseNonExpires.list_price }}</p>
                                    <p class="card-text">Kedvezményes ár: {{ courseNonExpires.price }}</p>
                                    <p class="card-text">Előfizetés hossza: {{courseNonExpires.month}}</p>
                                    <div class="description" *ngIf="courseNonExpires.desc">
                                      <p class="card-text" [innerHTML]="courseNonExpires.desc" [class.truncated-text]="courseNonExpires.isTextOverflow"></p>
                                      <p class="read-more-button text-right" *ngIf="isTextOverflowing(courseNonExpires)" (click)="toggleTextOverflow(courseNonExpires)">{{ courseNonExpires.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="preview" *ngIf="isAdmin">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <div class="card mb-3">
                      <div class="row no-gutters">
                          <div class="card-div mx-auto">
                              <p class="card-title p-2">Kurzus előnézet</p>
                              <div *ngFor="let preview of coursePreviews">
                                      <div class="card-body">
                                          <div class="course-title">
                                            <span class="card-text-name">{{ preview.title }}</span>
                                            <button class="btn bcolor"
                                                  (click)="prev(preview)">Előnézet</button>
                                          </div>

                                          <p class="card-text">Ár:
                                            {{ preview.price }}
                                          </p>
                                          <p class="card-text">Előfizetés hossza: {{preview.month}}
                                          </p>
                                          <div class="description" *ngIf="preview.desc">
                                            <p class="card-text" [innerHTML]="preview.desc" [class.truncated-text]="preview.isTextOverflow"></p>
                                            <p class="read-more-button text-right" *ngIf="isTextOverflowing(preview)" (click)="toggleTextOverflow(preview)">{{ preview.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                          </div>
                                          <div class="line"></div>
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    <div *ngIf="!loggedUser">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div>
                            <div class="card-div mx-auto">
                              <div class="card-body" *ngFor="let product of productsVidForLicens">
                                <div class="course-title">
                                  <p class="card-text-name">{{ product.title }}</p>
                                  <button routerLink="/fizetes" class="btn bcolor mt-3"
                                          (click)="addCart(product.type, product.type_id)">Előfizetek</button>
                                </div>

                                  <p *ngIf="product.list_price" class="card-text">Lista ár: {{product.list_price}}</p>
                                  <p class="card-text">Ár: {{product.price}}</p>
                                  <p class="card-text">Előfizetés hossza: {{product.month}}
                                  <div class="description" *ngIf="product.desc">
                                    <p class="card-text" [innerHTML]="product.desc"></p>
                                    <p class="read-more-button text-right" *ngIf="isTextOverflowing(product)" (click)="toggleTextOverflow(product)">{{ product.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                  </div>
                              </div>
                                <div class="card-body" *ngFor="let product of productsForLicens">
                                  <div class="course-title">
                                    <p class="card-text-name">{{ product.title }}</p>
                                    <button routerLink="/fizetes" class="btn bcolor mt-3"
                                            (click)="addCart(product.type, product.type_id)">Előfizetek</button>
                                  </div>

                                    <p *ngIf="product.list_price" class="card-text">Lista ár: {{product.list_price}}</p>
                                    <p class="card-text">Ár: {{product.price}}</p>
                                    <p class="card-text">Előfizetés hossza: {{product.month}}
                                    <div class="description" *ngIf="product.desc">
                                      <p class="card-text" [innerHTML]="product.desc"></p>
                                      <p class="read-more-button text-right" *ngIf="isTextOverflowing(product)" (click)="toggleTextOverflow(product)">{{ product.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="overlay" *ngIf="showModal"></div>
    <div class="modal fade modalc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
        id="exampleModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                            (click)="closeModal()">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ modalContent }}</div>
                    </div>

                    <div class="modal-footer" *ngIf="platform !== 'cancel-recurring'">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            (click)="closeModal()">Bezárás</button>
                    </div>
                    <div class="modal-footer" style="display:flex; justify-content: space-around;" *ngIf="platform == 'cancel-recurring'">
                      <button type="button" class="btn btn-primary c"
                          (click)="doModalAction()">{{ modalActionTitle }}</button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal"
                          (click)="closeModal()"> {{ modalCancelTitle }}</button>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>

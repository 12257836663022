import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, forkJoin, map, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { sl } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class FooldalService {
  private baseUrl = environment.apiBaseUrl;
  private paypalBaseUrl = environment.paypalBaseUrl;


  getBaseUrl() {
    return this.baseUrl;
  }

  constructor(private http: HttpClient,
    private authService: AuthService) {

  }

  getMenu() {
    return this.http.get(`${this.baseUrl}/system/menu/main/linkset`);
  }

  getId() {
    return this.http.get(`${this.baseUrl}/jsonapi/node/page`);

  }

  /*getFooldal(id: string){
    return this.http.get(`${this.baseUrl}/jsonapi/node/page/` + id + `?include=field_paragraphs.field_image_full.field_media_image`);
  }*/

  getFooldal(id: string) {

    // Define your headers
    const headers = new HttpHeaders({
      //'X-CSRF-Token': '6IjndErFylRc84oYGRh5XkvKinrjCaPCFFp1lD7pMGc',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    // Include the headers in the options object
    const httpOptions = {
      headers: headers,
    };

    return this.http.get(`${this.baseUrl}/jsonapi/node/page/` + id, httpOptions);
  }

  getPhotos(id: string, media: string, image: string) {
    return this.http.get(`${this.baseUrl}/jsonapi/` + media + '/' + image + '/' + id);
  }

  getCatNames() {
    return this.http.get(`${this.baseUrl}/jsonapi/taxonomy_term/videostore_categories`);
  }

  getRotation() {
    return this.http.get(`${this.baseUrl}/jsonapi/taxonomy_term/rotation`);
  }

  getToken(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/session/token`, { responseType: 'text' as 'json' });
  }

  getVideos(url?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    // Ha van megadva URL, akkor azt használjuk, különben az alap URL-t
    const apiUrl = url || `${this.baseUrl}/jsonapi/node/videostore?sort=field_weight,id`;

    return this.http.get(apiUrl, { headers });
  }

  getVideoStoreItemByMediaId(mid: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    return this.http.get<any>(`${this.baseUrl}/jsonapi/node/videostore?filter[field_video.field_video.drupal_internal__mid]=${mid}`, { headers });
  }

  getLessonItemByMediaId(mid: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get<any>(`${this.baseUrl}/jsonapi/node/topic?filter[field_lessons.field_video.drupal_internal__mid]=${mid}`, { headers });

  }


  deleteVideos(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.delete(`${this.baseUrl}/jsonapi/node/videostore/` + id, { headers });
  }

  createMediaVideo(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.post(`${this.baseUrl}/jsonapi/media/video`, data, { headers });
  }

  createMediaImage(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.post(`${this.baseUrl}/jsonapi/media/image`, data, { headers });
  }

  sendVideo(data: FormData, filename: string, id: string) {
    const encodedFilename = encodeURIComponent(filename);
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/vnd.api+json',
      'Content-Disposition': 'file; filename="' + encodedFilename + '"',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    return this.http.post(`${this.baseUrl}/jsonapi/media/video/` + id + '/field_media_video_file', data.get('file'), { headers });
  }

  sendImage(data: FormData, filename: string, id: string) {
    const encodedFilename = encodeURIComponent(filename);
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/vnd.api+json',
      'Content-Disposition': 'file; filename="' + encodedFilename + '"',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    return this.http.post(`${this.baseUrl}/jsonapi/media/image/` + id + '/field_media_image', data.get('file'), { headers });
  }

  patchVideo(data: any, video_id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.patch(`${this.baseUrl}/jsonapi/media/video/` + video_id, data, { headers });
  }

  createThumbnail(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.post(`${this.baseUrl}/jsonapi/media/image`, data, { headers });
  }

  createVideoParagraph(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.post(`${this.baseUrl}/jsonapi/paragraph/video`, data, { headers });
  }

  sendVideoStore(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.post(`${this.baseUrl}/jsonapi/node/videostore`, data, { headers });
  }

  getCourses() {
    return this.http.get(`${this.baseUrl}/api/courses`);
  }

  getCoursesId() {
    return this.http.get(`${this.baseUrl}/api/courses`).pipe(
      map((response: any) => {
        if (response.courses) {
          const courses = response.courses;
          const cids: string[] = [];

          for (const courseId in courses) {
            if (courses.hasOwnProperty(courseId)) {
              const cid = courses[courseId].cid[0].value;
              cids.push(cid);
            }
          }

          return cids;
        } else {
          throw new Error('Hibás API válasz struktúra');
        }
      })
    );
  }

  getCourseOutlineByPreviewTitle(title: string) {
    return this.getPreviews().pipe(
      switchMap((previews: any) => {
        for(let p of previews.data) {

          if(p.title === title) {
            let courseUuid = p.field_course.id;
            return this.getCourses().pipe(
              switchMap((courses: any) => {
                for(let course of Object.values(courses.courses)) {
                  const c = course as any;
                  if(c.uuid[0].value === courseUuid) {
                    return this.enrolledUserOutline(c.cid[0].value);
                  }
                }
                return EMPTY;
              })
            );
          }
        }
        return EMPTY;
      })
    );
  }

  enrolledUser() {
    return this.http.get(`${this.baseUrl}/api/courses?enrolled=1`);
  }

  nonEnrolledUser() {
    return this.http.get(`${this.baseUrl}/api/courses?enrolled=0`);
  }

  enrolledUserOutline(cid: string) {
    return this.http.get(`${this.baseUrl}/api/courseoutline/${cid}`);
  }

  getPage() {
    return this.http.get(`${this.baseUrl}/jsonapi/node/page`);
  }

  getPageAlias() {
    return this.http.get(`${this.baseUrl}/jsonapi/path_alias/path_alias`);
  }

  getPageFilter(id: number) {
    return this.http.get(`${this.baseUrl}/jsonapi/node/page?filter[drupal_internal__nid]=${id}`);
  }

  catPhotos(id: string) {
    const headers = new HttpHeaders({
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get(`${this.baseUrl}/jsonapi/media/image/` + id + `/field_media_image`, { headers });
  }

  getCurrentVideos(id: number) {
    const headers = new HttpHeaders({
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get(`${this.baseUrl}/api/videostore/current-videos/` + id, { headers });
  }

  getFavoritesVideos() {
    return this.http.get(`${this.baseUrl}/api/favorite-videos`);
  }

  likedVideos(data: any) {
    const headers = new HttpHeaders({
      "Content-type": 'application/json'
    });
    return this.http.post(`${this.baseUrl}/api/flag-entity`, data, { headers });
  }

  enrolledCourseLicens() {
    return this.authService.getCurrentUserUUID().pipe(
      switchMap((currentUserUuid: any) => this.http.get(`${this.baseUrl}/jsonapi/licenses/role?filter[state]=active&filter[uid.id]=${currentUserUuid}`))
      //switchMap((currentUserUuid: any) => this.http.get(`${this.baseUrl}/jsonapi/licenses/role?filter[state]=active`))
    );
  }

  allLicensesOfCurrentUser() {
    return this.authService.getCurrentUserUUID().pipe(
      switchMap((currentUserUuid: any) => this.http.get(`${this.baseUrl}/jsonapi/licenses/role?filter[uid.id]=${currentUserUuid}`))
      //switchMap((currentUserUuid: any) => this.http.get(`${this.baseUrl}/jsonapi/licenses/role`))
    );
  }


  getPreviews() {
    return this.http.get(`${this.baseUrl}/jsonapi/products/preview`)
  }

  getAllUsers() {
    const headers = new HttpHeaders({
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    })
    return this.http.get(`${this.baseUrl}/jsonapi/user/user`, { headers });
  }

  getAllProducts() {
    return this.http.get<any>(`${this.baseUrl}/jsonapi/products/default?sort=field_weight`);
  }

  getAllVideoStoreProducts(){
    return this.http.get<any>(`${this.baseUrl}/jsonapi/products/videostore?sort=field_weight`);
  }

  addItemToCart(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    });
    return this.deleteCart().pipe(
      switchMap(() => this.http.post<any>(`${this.baseUrl}/jsonapi/cart/add`, data, { headers }))
    );
  }

  getProfileCustomer() {
    return this.http.get(`${this.baseUrl}/jsonapi/profile/customer`);
  }

  updateOrder(data: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    });
    return this.http.patch(`${this.baseUrl}/jsonapi/checkout/` + id, data, { headers });
  }

  getCurrentCustomerProfile() {
    return this.authService.getCurrentUser().pipe(
      switchMap((currentUser: any) => {
        const profileUuid = currentUser.data[0].relationships.customer_profiles.data[0].id;
        return this.http.get(`${this.baseUrl}/jsonapi/profile/customer/${profileUuid}`)
      }),
      catchError((error) => {
        if(error.status === 404) {
          return of({});
        } else {
          return throwError(() => error);
        }
      })
    );

  }

  addProductWithCart(data: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    });
    return this.http.patch(`${this.baseUrl}/jsonapi/checkout/` + id , data, { headers });
  }

  sendToCheckout(data: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.patch(`${this.baseUrl}/jsonapi/checkout/` + id , data, { headers });
  }

  getCurrentCart() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.authService.getCurrentUserUUID().pipe(
      switchMap((currentUserUuid: any) => this.http.get(`${this.baseUrl}/jsonapi/orders/default?filter[cart]=1&filter[uid.id]=${currentUserUuid}`, { headers }))
    );
  }

  deleteCart() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.authService.getCurrentUserUUID().pipe(
      switchMap((currentUserUuid: any) => this.http.get(`${this.baseUrl}/jsonapi/orders/default?filter[cart]=1&filter[uid.id]=${currentUserUuid}`, { headers })),
      switchMap((response: any) => {
        if(response.data?.length > 0) {
          let calls: any[] = []
          response.data.forEach((cart:any) => {
            calls.push(this.http.delete(`${this.baseUrl}/jsonapi/orders/default/` + cart.id, { headers }));
          });
          return forkJoin(calls);
        } else {
          // Return an empty observable if there is no data
          return of({});
        }
      })
    );
  }

  addCuopon(data: any, id: any) {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.post(`${this.baseUrl}/jsonapi/orders/default/` + id +`/relationships/coupons`, data, {headers})

  }

  getAllCuopons(){
    return this.http.get(`${this.baseUrl}/jsonapi/promotion-coupons`);
  }

  getCouponByCode(code:string){
    return this.http.get(`${this.baseUrl}/jsonapi/promotion-coupons?filter[code]=${code}`);
  }

  getPromotionById(id: any){
    return this.http.get<any>(`${this.baseUrl}/jsonapi/promotions/${id}`);
  }

  getProductById(id: any){
    return this.http.get(`${this.baseUrl}/jsonapi/orders/default/` + id);
  }

  getPaypalGatewayData() {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get<any>(`${this.baseUrl}/jsonapi/payment-gateways?filter[status]=1&filter[plugin]=paypal_checkout`, {headers});

  }

  startSimplePayPayment(orderUuid: string) {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get<any>(`${this.baseUrl}/api/simplepay/startpayment/${orderUuid}`, {headers});
  }

  createSimplePayOrder(data: any,signature: string) {
    const headers = new HttpHeaders ({
      'Signature': signature,
    });
    return this.http.post<any>(environment.simplepayBaseUrl + `/payment/v2/start`, data, {headers});
  }

  setOrderAsCompleted(orderUuid: string) {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    const body = {
      "data": {
        "type": "commerce_order--order",
        "id": orderUuid,
        "attributes": {
          "state": "completed"
        }
      }
    }
    return this.http.patch<any>(`${this.baseUrl}/jsonapi/orders/default/${orderUuid}`, body, {headers});
  }

  getSimplePayGatewayData() {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get<any>(`${this.baseUrl}/jsonapi/payment-gateways?filter[status]=1&filter[plugin]=commerce_otpsp_redirect`, {headers});
  }

  getPayPalAccesToken(data: any){
    return this.getPaypalGatewayData().pipe(
      map(gw => [gw.data[0].attributes.configuration.client_id, gw.data[0].attributes.configuration.secret]),
      map(([clientId, secret]) => {
        const auth = btoa(clientId + ':' + secret);
        const headers = new HttpHeaders({
          'Authorization': 'Basic ' + auth,
          'Content-Type': 'application/x-www-form-urlencoded'
        });
        return headers;
      }),
      switchMap(headers => this.http.post(`${this.paypalBaseUrl}/v1/oauth2/token`, data, { headers }))
    );
  }

  createPayPalOrder(data: any, accesToken: string){
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accesToken,
      'Content-Type': 'application/json',
    });
    return this.http.post(`${this.paypalBaseUrl}/v2/checkout/orders`, data, { headers });
  }

  getPaypalOrder(paymentToken: any, accesToken: string){
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accesToken,
      'Content-Type': 'application/json',
    });
    return this.http.get(`${this.paypalBaseUrl}/v2/checkout/orders/${paymentToken}`, { headers });
  }

  addPaymentMethod(data: any, id: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    return this.http.patch(`${this.baseUrl}/jsonapi/checkout/` + id, data, {headers});
  }

  approvePayment(id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });
    return this.http.get(`${this.baseUrl}/jsonapi/checkout/` + id + `/payment/approve`, { headers });
  }

  approvePaymentSimple(id: any, r:any, s:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
    });

    const encodedR = encodeURIComponent(r);
    const encodedS = encodeURIComponent(s);
    const queryString = `r=${encodedR}&s=${encodedS}`;

    return this.http.get(`${this.baseUrl}/jsonapi/checkout/` + id + `/payment/approve?${queryString}`, { headers });
  }

  startPayment(data: any, id: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    });

    return this.http.post(`${this.baseUrl}/jsonapi/checkout/` + id + `/payment`, data, {headers});
  }

  patchVideoWatched(data: any, id: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    });

    return this.http.patch(`${this.baseUrl}/jsonapi/course_object_fulfillment/course_object_fulfillment/` + id, data, {headers});
  }

  getFaqCat(){
    return this.http.get(`${this.baseUrl}/jsonapi/taxonomy_term/faq_categories?sort=weight`);
  }

  getScreeningQuestions(id: any){
    return this.http.get(`${this.baseUrl}/jsonapi/node/faq?sort=field_weight&filter[field_faq_category.id]=` + id);
  }

  getHallSessionProduct(){
    const headers = new HttpHeaders({
      'api-key' : '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
    })
    return this.http.get(`${this.baseUrl}/jsonapi/products/hallsession`, {headers});
  }

  postHallBook(data: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    })
    return this.http.post(`${this.baseUrl}/jsonapi/hallsession_appointment/default`, data, {headers});
  }

  getPromotions(){
    return this.http.get(`${this.baseUrl}/jsonapi/promotions`)
  }


  getCountryCode(){
    return this.http.get(`${this.baseUrl}/api/countrylist`);
  }

  cancelLicense(licenseUuid: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    });
    return this.http.patch(`${this.baseUrl}/jsonapi/subscriptions/license/` + licenseUuid, {
      data: {
        type: 'subscription--license',
        id: licenseUuid,
        attributes: {
          "scheduled_changes": [
            {
              "field_name": "state",
              "value": "canceled",
              "created": this.formatDateToPHP(new Date())
            }
          ]
        }
      },

    }, { headers });
  }

  formatDateToPHP(date: Date) {
    const pad = (n:number) => (n < 10 ? '0' : '') + n;
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    const timezoneOffset = -date.getTimezoneOffset();
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
    const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${offsetHours}${offsetMinutes}`;
}

  getSubscriptionForVariationUuid(uuid: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json'
    });
    return this.http.get(`${this.baseUrl}/jsonapi/subscriptions/license?filter[purchased_entity.id]=${uuid}`, { headers });
  }
}

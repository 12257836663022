<div class="wrapper mx-auto">
    <div class="arrow-steps clearfix">
        <div class="step"> <span>Adatok</span> </div>
        <div class="step"> <span>Összegzés</span> </div>
        <div class="step current"><span> Véglegesítés</span> </div>
    </div>
</div>
<div class="message m-5 p-3 mx-auto">
    Sikertelen fizetés, kérünk próbáld újra!
</div>
<div class="m-5">
    <div class="d-flex justify-content-center">
        <button routerLink="/osszegzes" class="btn btn-primary m-3 p-2">Összegzés</button>
    </div>
</div>
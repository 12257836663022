import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { FooldalService } from './services/fooldal.service';


@Injectable()
export class AdminInterceptorService implements HttpInterceptor {
  private baseUrl = environment.apiBaseUrl;
    constructor(private fooldalService: FooldalService, private authService: AuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Ellenőrizd, hogy a kérés a megfelelő szolgáltatás URL-ét tartalmazza-e
        if (request.url.includes(this.baseUrl + '/jsonapi/products/preview') || request.url.includes(this.baseUrl + '/jsonapi/paragraph/video') || request.url.includes(this.baseUrl + '/jsonapi/node/videostore') || request.url.includes(this.baseUrl + '/jsonapi/media/image') || request.url.includes(this.baseUrl + '/jsonapi/media/video') || request.url.includes(this.baseUrl + '/jsonapi/promotions' || this.baseUrl + '/user/register?_format=json') || request.url.includes(this.baseUrl + '/jsonapi/path_alias/path_alias') || request.url.includes(this.baseUrl + '/jsonapi/orders/default') || request.url.includes(this.baseUrl + '/jsonapi/checkout') || request.url.includes(this.baseUrl + '/jsonapi/promotion-coupons') || request.url.includes(this.baseUrl + '/jsonapi/course_object_fulfillment/course_object_fulfillment') || request.url.includes(this.baseUrl + '/jsonapi/user/user') ) {
          const uid = this.authService.getAuthenticatedUserID();
          return this.fooldalService.getToken().pipe(
            switchMap((token) => {
              if(!uid) {
                const storedApiKey = localStorage.getItem('api-key') || 'default_api_key';
                const converted = storedApiKey.replace(/"/g, '');
                    const headers = {
                      'X-CSRF-Token': token,
                      'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
                    };
                    const modifiedRequest = request.clone({
                      setHeaders: headers,
                    });
                    return next.handle(modifiedRequest);

              }
               else {
                const storedApiKey = localStorage.getItem('api-key') || 'default_api_key';
                const converted = storedApiKey.replace(/"/g, '');
                const headers = {
                  'X-CSRF-Token': token,
                  'api-key': '4d6b1b9d7ce8eddd9e81a4a0150c3d34'
                };
                const modifiedRequest = request.clone({
                  setHeaders: headers,
                });
                return next.handle(modifiedRequest);
               }
          }));
        }
        // Ha nem a megfelelő szolgáltatás kérését kapjuk, akkor nem módosítunk semmit
        return next.handle(request);
      }
}

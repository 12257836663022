<div class="container">
    <div class="row">
        <div class="col-lg-12 py-3">
            <form [formGroup]="lostpw" class="col-sm-6" style="margin: 0 auto;">
                <div class="mb-3">
                  <label class="form-label">Email cím</label>
                  <input formControlName="email" type="email" class="form-control">
                  <div *ngIf="this.lostpw.get('email')?.errors?.['required'] && (this.lostpw.get('email')?.dirty || this.lostpw.get('email')?.touched)" 
                  class="error-message">
                    Email cím megadása kötelező
                  </div>
                  <div *ngIf="this.lostpw.get('email')?.errors?.['email'] && (this.lostpw.get('email')?.dirty || this.lostpw.get('email')?.touched)" 
                  class="error-message">
                    Hibás email formátum
                  </div>
                </div>
                <button type="button" class="btn btn-primary c" (click)="lostPw()">Jelszó emlékeztető küldése</button>
              </form>
        </div>
    </div>
    <div class="overlay" *ngIf="showModal"></div>
    <div class="modal fade modalc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
        id="exampleModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                            (click)="closeModal()">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ modalContent }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            (click)="closeModal()">Bezárás</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RolamsafePipe } from '../pipes/rolamsafe.pipe';
import { FooldalService } from '../services/fooldal.service';
import { VideoPlayerComponent } from './video-player/video-player.component';



@NgModule({
  declarations: [
    VideoPlayerComponent,
    RolamsafePipe
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [VideoPlayerComponent, RolamsafePipe],
  providers: [
    FooldalService

  ]
})
export class SharedModule { }

import { Component } from '@angular/core';
import { FooldalService } from 'src/app/services/fooldal.service';

@Component({
  selector: 'app-sikertelen-kurzus',
  templateUrl: './sikertelen-kurzus.component.html',
  styleUrls: ['./sikertelen-kurzus.component.css']
})
export class SikertelenKurzusComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];

  constructor(private fooldalService: FooldalService) { }

  ngOnInit() {
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)

  }


}

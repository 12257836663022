import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FooldalService } from 'src/app/services/fooldal.service';

@Component({
  selector: 'app-sikeres-kurzus',
  templateUrl: './sikeres-kurzus.component.html',
  styleUrls: ['./sikeres-kurzus.component.css']
})
export class SikeresKurzusComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];

  constructor(private fooldalService: FooldalService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const simple = this.route.snapshot.queryParamMap.has('r');
    if(simple) {
      this.processSimplePay();
    } else {
      this.processPayPal();
    }
  }

  private processSimplePay() {
    const r = this.route.snapshot.queryParamMap.get('r')!;
    const s = this.route.snapshot.queryParamMap.get('s')!;
    const response = JSON.parse(atob(r));
    console.log(response);
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)

    var localPId = localStorage.getItem('productId');

    this.fooldalService.getSimplePayGatewayData().subscribe(gw => {
      const data = gw.data[0];

    const paymentBody = {
      "data":
        {
          "type": "order--default",
          "id": response.o,
          "attributes": {
            "payment_instrument": {
              "payment_gateway_id": data.attributes.label,
              "payment_method_type": "credit_card",
              "payment_gateway_mode": data.attributes.configuration.mode,
            }
          }
        }
    }

    this.fooldalService.approvePaymentSimple(localPId, r , s).subscribe( f => {
      setTimeout(() => {
        //this.router.navigateByUrl('/elofizetes');
      }, 5000);
    });

    /*
    this.fooldalService.addPaymentMethod(paymentBody, localPId).subscribe( p => {
        this.fooldalService.approvePaymentSimple(localPId, r , s).subscribe( f => {
        });
      });
      */
  });
  }

  private processPayPal() {
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)

    var localPId = localStorage.getItem('productId');
    var paypalId = localStorage.getItem('paypalApi');

    this.fooldalService.getPaypalGatewayData().subscribe(gw => {
      const data = gw.data[0];

    const paymentBody = {
      "data":
        {
          "type": "order--default",
          "id": localPId,
          "attributes": {
            "payment_instrument": {
              "payment_gateway_id": data.attributes.label,
              "payment_method_type": "paypal_checkout",
              "payment_gateway_mode": data.attributes.configuration.mode,
              "payment_details": {
                "paypal_remote_id": paypalId
              }
            }
          }
        }
    }

    const startBody = {
      "data": {
        "type": "payment--paypal-checkout",
        "attributes": {
          "capture": true
        }
      }
    }


    this.fooldalService.addPaymentMethod(paymentBody, localPId).subscribe( p => {
      this.fooldalService.startPayment(startBody, localPId).subscribe( f => {
        setTimeout(() => {
          // this.router.navigateByUrl('/elofizetes');
        }, 5000);
      })
    });
  });
  }
}
